@import "~assets/css/variable.scss";

.relative {
  position: relative;
}

.text-underline {
  text-decoration: underline;
}

.second-part-image {
  background-image: url("../../assets/images/Signup_1.jpg");
}

.calculator {
  // border-bottom: 1px solid #CACED2;

  .calculator-container {
    max-width: 1440px;
    margin: 0 auto;
    // height: 100%;
  }

  &.cal-popup {
    height: inherit;
    // overflow-y: scroll;
    overflow-x: hidden;
  }

  // &.calc-window {
  //   height: 100%;
  // } 

  .calc-title {
    font-size: 32px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 35px;
    font-weight: $font-bold;
  }

  .calc-subtitle {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  .col-4 {
    padding: 0;
  }

  .calculator-form {
    padding-top: 35px;
    padding-right: 35px;

    .field-title {
      font-size: 16px;
      color: $grey-light;
      line-height: 20px;
    }

    .section-description {
      font-size: 16px;
      line-height: 24px;
      margin: 20px 0;
      color: rgba(0, 0, 0, 0.85);
    }

    .radio-agent {
      display: flex;
      place-content: flex-start;
    }

    .bank-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: 15%;
      width: 95%;

      // img {
      //   padding-right: 30px;
      // }
    }
  }

  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-o-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .animate-flicker {
    -webkit-animation: flickerAnimation 1s infinite;
    -moz-animation: flickerAnimation 1s infinite;
    -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
  }

  .calculator-details {
    padding: 20px 40px;

    h3 {
      font-size: 30px;
      line-height: 36px;
      font-weight: $font-bold;
      margin-top: 35px;
      margin-bottom: 25px;
    }

    .animate-flicker {
      -webkit-animation: flickerAnimation 1s infinite;
      -moz-animation: flickerAnimation 1s infinite;
      -o-animation: flickerAnimation 1s infinite;
      animation: flickerAnimation 1s infinite;
    }

    .title {
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 10px;

      span {
        color: $primary-color;
        border-radius: 5px;
        padding: 0 5px;
        font-weight: $font-bold;
      }
    }

    .section-header {
      padding: 25px 0;
      border-bottom: 1px solid #e9ecef;
      align-items: center;

      .rec-text {
        font-size: 16px;
        color: $grey-light;
        line-height: 21px;
        font-weight: $font-bold;
      }

      .btn {
        font-size: 14px;
        min-width: 165px;
        font-weight: $font-bold;
        height: 38px;
        border-radius: 19px;

        &:hover {
          background-color: $hover-red !important;
        }
      }
    }

    .section-description {
      b {
        color: $primary-color;
      }
    }
  }

  .option-input {
    cursor: pointer;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input {
      font-size: 14px !important
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    &.number-increment {
      position: relative;

      input {
        &:focus {
          border-color: transparent;
        }
      }

      .option-buttons {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 39px;
        height: 43px;
        text-align: center;
        border-radius: 12px;
        top: 0;
        transition: all 0.4s ease;
        font-size: 16px;
        color: $grey-light;
        line-height: 14px;
        font-weight: $font-bold;

        i {
          font-size: 16px;
          transition: all 0.4s ease;

          &.fa-minus {
            margin-top: 3px;
          }
        }

        &:hover {
          background-color: #e6e6e6;
          color: $grey-light;
        }

        &.button-increment {
          right: 0px;
        }

        &.button-decrement {
          left: 0px;
        }
      }
    }

    input {
      height: 43px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      margin: 0 auto;
      font-size: 16px;
      color: rgba(79, 89, 108, 1);
      text-align: left;
      line-height: 14px;
      padding-left: 10px;

      &:focus {
        border: 1px solid #ced4da !important;
      }
    }

    &.number-increment input {
      width: 100%;
      border: 1px solid #ced4da;

      &:focus,
      &:active {
        outline: none !important;
      }
    }
  }

  .annul-div {
    display: flex;
    place-content: space-between;
  }

  .annual-label {
    font-weight: $font-bold;
  }

  .annual-range {
    display: flex;
    align-items: center;
    width: 100%;
    place-content: space-between;
    margin: 0 auto;

    .input-range__slider {
      background-color: $primary-color;
      border-color: $primary-color;
      margin-top: -12px;
    }

    .input-range__track {
      height: 8px;
    }

    i {
      cursor: pointer;
    }

    .input-range__label {
      font-family: $font;
    }

    .input-range__label--min {
      left: 10px;
    }

    .input-range__label--max {
      right: 7px;
    }

    .input-range__label--value {
      position: absolute;
      top: -18px;
      left: 18px;
      background-color: white;
      // padding: 4px 10px;
      width: 42px;
      height: 26px;
      box-shadow: 0 0 10px grey;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 7px;
      z-index: 2;

      &::after {
        position: absolute;
        top: 5.5px;
        content: "";
        left: -6px;
        border-top: 7px solid transparent;
        border-right: 7px solid white;
        border-left: none;
        border-bottom: 7px solid transparent;
      }

      .input-range__label-container {
        left: 0;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        color: $primary-color;
      }
    }
  }

  .dropdown {
    display: flex;
    place-content: center;

    .dropdown-toggle:focus {
      box-shadow: none !important;
      border-color: #ced4da !important;
    }

    .dropdown-item:active,
    .dropdown-item:focus {
      background-color: $primary-color !important;
    }

    .dropdown-toggle::after {
      float: right;
      margin-top: 10px;
      border-top: 0.45em solid !important;
    }

    .dropdown-menu.show {
      width: 330px;
    }

    .dropdown-toggle,
    .dropdown-toggle:hover {
      color: $grey-light !important;
    }

    .btn-primary {
      background-color: $white-color !important;
      color: $grey-light !important;
      border-radius: 4px;
      width: 100%;
      border: 1px solid #ced4da;
      height: 43px;
      font-size: 16px;
      text-align: left;

      &.hover {
        color: $grey-light !important;
      }
    }
  }
}

.calc-mobile {
  .filter-part {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
  }

  .scroll-to-top-btn {
    position: fixed;    
    // top: 10px;
    bottom: 11%;
    right: 10px;
    z-index: 1;
    background-color: $primary-color;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  .col-12 {
    padding: 20px !important;
  }

  .calculator-details {
    padding: 0;

    h3 {
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
    }

    .title {
      font-size: 18px;
      line-height: 27px;
    }

    .calc-detail-row {
      .table-title {
        width: 45%;
      }

      .table-data {
        width: 25%;
      }
    }

    .time-line-text {
      font-size: 18px;
      line-height: 27px;
    }
  }

  .calculator-form {
    padding: 0;
  }

  .calc-title {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
  }

  .calc-graph-model {
    .calc-title {
      color: $primary-color;
    }
  }

  .calc-btn {
    margin: 30px 0 0 0;
  }
}

.calc-mobile .calculator-form .bank-group {
  margin-top: 0 !important;
}

.contact-form-pop-up-wrapper {
  max-height: calc(100vh - 50px);
  width: 100% !important;
  border-radius: 5px;

  .close-btn {
    border-color: $white-color;
    position: absolute;
    right: 24px;
    top: 16px;
    height: 18px;
    width: 18px;
    cursor: pointer;
    z-index: 9;

    svg {
      fill: $white-color
    }
  }

  .second-part .slider-wrapper .slide img {
    height: 250px;
  }

  .new-interested-popup .success-info {
    min-height: 390px;
    margin: 50px 0;
  }

  .new-contact-page .first-part .login-form-popup .login-input-box,
  .landing-page-signup .first-part .login-form-popup .login-input-box {
    height: 40px !important;
    font-size: 14px !important;
  }

  .first-part {
    margin-top: 0 !important;

    .logo-wrapper {
      display: none;
    }

    .logo-text-popup {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 18px;
    }

    .radio-container {
      margin: 10px 0 3px 0 !important;
    }
  }

  .second-part {
    min-height: 100% !important;
  }

  .slick-dots {
    bottom: -15px !important;
  }
}

.cal-popup {
  width: 100%;
  background-color: white;
  // border-radius: 23px;
  height: 85vh;
  max-height: 800px;
  margin: 0 !important;

  .calculator-form {
    .row {
      margin: 0 -15px;
    }
  }
}

.highcharts-point {
  display: none !important;
}

.highcharts-plot-line-label {
  position: absolute;
  font-size: 14px;
  white-space: nowrap;
  margin-left: 0px;
  margin-top: 0px;
  transform: rotate(0deg);
  transform-origin: 0% 12px;
  left: 230px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.12);
  line-height: 16px;
  padding: 10px 25px;
  border-radius: 8px;
  visibility: inherit;
  z-index: 10002;
}

.calculator-detail-section {
  .calc-detail-row {
    display: flex;
    place-content: space-between;
    align-items: center;
    margin: 20px 0;

    p {
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.65);
    }

    .table-title {
      width: 60%;
    }

    .table-per-data {
      width: 15%;
      text-align: left;
    }

    .table-data {
      width: 20%;
      font-weight: $font-bold;
      text-align: right;
    }
  }


}

.calculator-footer {
  height: 124px;
  background: $white-color;
  box-shadow: 20px 0px 30px rgba(0, 0, 0, 0.2);
  position: relative;

  .footer-text {
    font-size: 20px;
    font-weight: $font-bold;
  }
}

.calc-btn {
  height: 64px;
  border-radius: 40px;
  width: 306px;
  border: 1px solid $grey-for-button;
  background-color: $white-color;
  color: #444;
  font-weight: $font-bold;
  font-size: 16px;
  margin: 30px 0;

  &.red {
    background-color: $primary-color;
    color: $white-color;
  }
}


// .highcharts-tooltip>span {
//   background: white;
//   border: 1px solid silver;
//   border-radius: 15px;
//   box-shadow: 1px 1px 20px #888;
//   padding: 15px;

//   * {

//     font-size: 18px;
//   }

//   &::after {
//     position: absolute;
//     bottom: -10px;
//     content: "";
//     right: calc(50% - 10px);
//     border-top: 10px solid white;
//     border-right: 10px solid transparent;
//     border-bottom: 0;
//     border-left: 10px solid transparent;
//   }

//   text-align: center;

// }

.calc-interest-container {
  max-height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 30px;

  .close-btn {
    border-color: #000;
    position: absolute;
    right: 24px;
    top: 16px;
    height: 18px;
    width: 18px;
    cursor: pointer;

    svg {
      path {
        fill: $border-grey;
      }
    }
  }

  .accordion-body table td {
    padding: 13px 10px;
    font-size: 14px;
    line-height: 1.4;
    color: rgba(0, 0, 0, 0.65);
    font-weight:$font-regular;
  }

  .accordion-body table tr {
    border-bottom: 1px solid #e9ecef;
    height: 48px;
  }

  .calc-graph-model {
    margin: 0 auto;
    width: 629px;
    padding-top: 30px;

    .time-line-text {
      font-size: 16px;
      line-height: 24px;
      text-align: center;

      span {
        font-weight: $font-bold;
      }
    }
  }

  .table-title-graph {
    font-size: 32px;
    color: $primary-color;
    font-weight: $font-bold;
    text-align: center;
  }

  .fixation-div {
    width: 850px;
    margin: 30px auto;

    .button-section {
      .btn {
        width: 92px;
        height: 37px;
        color: rgba(0, 0, 0, 0.45);
        border-radius: 40px;
        border: 1px solid rgba(0, 0, 0, 0.45);
        font-size: 14px;
      }
    }

    .row-title {
      color: $primary-color;
    }

    .active-tablehead {
      background-color: $primary-color;
      color: $white-color;
      font-weight: $font-bold;
      border: 1px solid $primary-color;
    }

    .active-tablerow {
      border-left: 1px solid $primary-color;
      border-right: 1px solid $primary-color;

      &.border-bottom {
        border-bottom: 1px solid $primary-color !important;
      }
    }

    th {
      padding: 5px;
    }

    td,
    th {
      width: 10%;
      padding-left: 10px;
    }


    td,
    th {
      width: 10%;
    }

    tr {
      &:last-child {
        border-bottom: none;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 18px;
    background-color: $white-color;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: $white-color;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: $primary-color;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.time-line-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 15px;

  span {
    color: $primary-color;
  }
}

.euro-label {
  position: absolute;
  margin-left: -41px;
  margin-top: 1px;
  background: #F5F5F5;
  width: 40px;
  height: 41px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  font-size: 16px;
  line-height: 41px;
  font-weight: $font-bold;
  color: rgba(0, 0, 0, 0.45);

  .arrow-icon {
    width: 16px;

    path {
      fill: rgba(0, 0, 0, 0.45)
    }
  }
}