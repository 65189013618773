@import "~assets/css/variable.scss";

.city-dropdown {
  margin-left: 10px;
}

.city-dropdown .btn-primary {
  color: $white-color  !important;
  border-color: $white-color  !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.city-dropdown-menu {
  background-color: $white-color;
  border-radius: 20px;
  position: absolute;
  z-index: 999;
  padding: 15px 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 3px 2px;
  cursor: pointer;
  top: 65px;
  margin-left: -225px;

  &:before {
    content: "";
    position: absolute;
    top: -18px;
    left: 260px;
    border-bottom: 20px solid $white-color;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
  }

  .city-list {
    height: 58px;
    width: 584px;
    text-align: initial;

    .img-pin {
      margin-left: 35px;
    }
  }

  label {
    text-align: center;
    font-size: 14px;
    color: $immozy-dark;
    letter-spacing: 0.06em;
    line-height: 18px;
    margin-bottom: 15px;
  }

  li {
    margin: 30px 0px 30px 0px !important;
    font-size: 14px;
    color: $immozy-dark;
    letter-spacing: 0.06em;
    transition: all 0.3s ease;
  }

  li:hover {
    background-color: $bg-white;
  }
}

.profile-dropdown-menu {
  background-color: $white-color;
  border-radius: 26px;
  position: absolute;
  z-index: 10002;
  padding: 17px 0px 22px 0px;
  top: 40px;
  right: 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 3px 2px;
  width: 170px;
  cursor: pointer;

  a,
  i {
    color: #4e5d75;
    transition: color 0.2s ease 0s;
  }

  li {
    padding: 10px;
    font-size: 14px;
    color: #4e5d75;
  }

  li:first-child {
    border-top-left-radius: 5px;
  }

  li:nth-child(2) {
    border-bottom-left-radius: 5px;
  }

  li:hover {
    background-color: $bg-white;
  }

  .error-profile {
    font-weight: $font-bold;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 13px;
    color: $white-color;
    background-color: $primary-color;
    text-align: center;
    line-height: 18px;
    z-index: 1;
    cursor: pointer;
    margin-left: 6px;
  }
}

.not-verified {
  position: absolute;
  right: -12px;
  top: -3px;
  color: #be3131;
  font-size: 27px;
  z-index: 11;
}


    .like-icon-favo {
      transition: all 0.5s ease;
      position: relative;
      background-color: $white-color;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      line-height: 37px;
      box-shadow: $btn-box-shadow;
      animation: fadein 0.2s;

      &.active {
        border-color: $primary-color  !important;
      }

      &.show {
        border: 1px solid $border-grey;
        cursor: pointer;

        &:hover {
          box-shadow: $btn-box-shadow-hover;

          i {
            color: $primary-color;
            font-weight: $font-bold;
          }
        }
      }

      i {
        font-size: 20px;
        color: $secondary-color;
      }

      .fa-heart {
        color: $primary-color;
      }

      .favorites-count {
        position: absolute;
        right: -15px;
        top: -3px;
        height: 19px;
        width: 19px;
        color: white;
        background: $primary-color;
        font-size: 11px;
        border-radius: 50%;
        text-align: center;
      }
    }

    .unverified-user-indicator {
      position: absolute;
      right: -10px;
      top: -2px;
      content: "!";
      font-weight: $font-bold;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      font-size: 13px;
      color: $white-color;
      background-color: $primary-color;
      text-align: center;
      line-height: 18px;
      z-index: 1;
      cursor: pointer;
    }

  .city-label {
    position: relative;
    height: 40px;
    width: 129px;
    background-color: $white-color;
    border: 1px solid $border-grey;
    border-radius: 21px;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.05em;
    box-shadow: $btn-box-shadow;
    transition: all 0.3s ease;
    padding-top: 8px;
    color: rgba(79, 89, 108, 1);
    text-transform: capitalize;

    &:hover {
      box-shadow: $btn-box-shadow-hover;
    }

    &.active {
      color: $primary-color;
      border-color: $primary-color;
    }

    img {
      margin-left: 5px;
    }

    .close-btn-city {
      position: absolute;
      margin-left: 33px;
      margin-top: -5px;
    }
  }

.profile-dropdown {
  position: relative;
  background-repeat: no-repeat;
  height: 35px;
  width: 76px;
  border: 1px solid $border-grey;
  border-radius: 18px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.12);
  transition: all 0.3s ease-in;

  &.active {
    border-color: $primary-color  !important;

    .profile-icon,
    .menu-icon {
      fill: $primary-color;
    }
  }

  &:hover {
    box-shadow: $btn-box-shadow-hover;
    border-color: $grey-light;
  }

  .profile-icon,
  .menu-icon {
    fill: $grey-light;
    width: 29px;
  }

  .menu-icon {
    width: 17px;
    margin-right: 10px;
  }
}

.verify-mail-success {
  color: green;
  padding-bottom: 5px;
  text-align: center !important;
  margin-top: 5px;
}

.email-close {
  position: relative !important;
  float: right !important;
  margin-top: -30px !important;
  margin-right: -40px !important;
}

.model-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
  z-index: -1;
  opacity: 0;
  padding: 80px 50px 50px;
  margin: 0;
  border: 1px solid grey;
  overflow: auto;

  &.show {
    z-index: 100;
    opacity: 1;
  }

  .close-btn-div {
    z-index: 11;
    text-align: center;
  }

  .edit-profile {
    position: relative;
    max-width: 575px;
    margin: 25px auto;
    border-radius: 20px;
    transition: 0.1s ease;

    &.show {
      background-color: $white-color;
    }

    &.hide-class {
      transition-delay: 0.6s;
    }

    label {
      font-size: 14px;
      color: $secondary-color;
      font-weight: $font-bold;
      line-height: 26px;
    }

    .verify-email-warning {
      background-color: #be3131;
      border-radius: 20px;
      padding: 25px;
      margin-top: -15px;
      box-shadow: 0px 8px 16px 8px rgba(0, 0, 0, 0.15);

      p {
        color: $white-color;
        font-size: 14px;
        font-weight: $font-bold;
        margin-bottom: 0;
        line-height: 1;
      }

      .underline-text {
        color: $white-color;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
        font-weight: $font-light;
        margin-top: 10px;
      }
    }

    .password-input {
      &:focus {
        border: 2px solid $primary-color  !important;
      }
    }

    input {
      height: 28px;
      font-size: 14px;
      color: $grey-light;
      border: 1px solid $border-grey;
      border-radius: 7px;
      outline: none !important;
      padding: 3px 12px !important;
      margin: 0;

      &:focus {
        border: 2px solid $primary-color;
      }
    }

    .button {
      height: 35px;
      max-width: 121px;
      width: 100%;
      background-color: $primary-color;
      border-radius: 18px;
      transition: all 0.3s ease;
      color: $white-color;

      &:hover {
        background-color: $hover-red;
      }
    }

    .row {
      margin: 0;

      .col-4,
      .col-8,
      .col-3,
      .col-9,
      .col-12 {
        padding: 0;
      }
    }

    .profile-details {
      padding: 20px 25px;
      color: $secondary-color;
      border-radius: 20px;
      box-shadow: 0px 2px 15px 4px rgba(0, 0, 0, 0.28);
      background-color: $white-color;

      .section-title {
        font-size: 16px;
        font-weight: $font-bold;
        letter-spacing: 1px;
      }

      .profile-picture {
        .profile-preview {
          height: 111px;
          width: 111px;
          background-color: #efefef;
          border: 6px solid $white-color;
          border-radius: 14px;
          box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.43);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
        }
      }

      .change-password-link {
        display: inline-block;
        margin-left: 10px;
        text-decoration: underline;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          color: $primary-color;
        }
      }

      .custom-radio {
        display: inline-block;
        margin-right: 15px;

        .custom-control-label {
          font-size: 16px;
          font-weight:$font-regular;
        }
      }

      .btn-submit {
        width: 112px;
        font-size: 14px !important;
      }
    }

    .profile-error {
      text-align: center;
      margin-bottom: 5px;
      color: $primary-color;
      font-size: 12px;
    }

    .profile-success {
      text-align: center;
      margin-bottom: 5px;
      color: #65c239;
      font-size: 12px;
    }
  }

  .change-password {
    height: 0;
    overflow: hidden;
    transition: height 0.6s linear;
    background-color: $white-color;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: 20px;

    &.show {
      height: 280px;
    }

    .profile-tab-wrapper {
      padding: 0 15px 15px;

      .pwd-div {
        width: 23% !important;

        &:first-child {
          margin-left: 0px !important;
        }

        &:last-child {
          margin-right: 0px !important;
        }
      }
    }

    .profile-tab {
      max-width: 395px;
      margin: 0 auto;

      .button {
        max-width: 210px;
      }

      label {
        font-weight:$font-regular;
        font-size: 15px;
      }
    }
  }

  .spinner-wrapper {
    position: absolute;
    right: -45px;
  }
}

.loading-background {
  position: fixed;
  left: 0;
  top: 80px;
  width: 100%;
  height: 100%;
  z-index: 99;
  cursor: progress;
  background: $primary-color;

  .loading-bar {
    position: absolute;
    left: 50%;
    width: unset;
    height: unset;
    transform: translate(-50%, -50%);
  }

  .loading-bar1 {
    top: 200px;
    animation: fadeIn 2s infinite alternate;
  }

  .loader-text {
    position: absolute;
    color: $white-color;
    cursor: default;
  }

  .loader-text-1 {
    font-size: 30px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    letter-spacing: 0.04em;
    line-height: 67px;
    top: 380px;
  }

  .loader-text-2 {
    font-size: 80px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    letter-spacing: 0.04em;
    line-height: 67px;
    top: 430px;
  }

  .loader-text-3 {
    font-size: 20px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    letter-spacing: 0.04em;
    line-height: 67px;
    top: 530px;
    animation: fadein 2s;
  }
}

.w-48 {
  width: 48% !important;
}

@keyframes fadeIn1 {
  from {
    opacity: 0.3;
  }
}


//------------------------------- new foter styling ------------------------------------------

#main-footer {
  position: relative;
  width: 100%;
  height: 285px;
  padding: 0;
  background-color: $primary-color;
  padding-top: 30px;

  .pdf-footer {
    background-color: $primary-color;
    padding: 30px 100px 10px;
    display: flex;
    max-width: 1380px;
    justify-content: space-between;
    align-items: flex-start;
    border-color: $primary-color;
    margin: 0 auto;
    position: relative;
    z-index: 5;
    border: none;

    .col {
      width: 50%;
      float: right;
    }

    table {
      border-collapse: separate;

      th,
      td {
        &:first-child {
          width: 200px;
        }

        color: $white-color;

        a {
          color: $white-color;

          &:hover {
            border-bottom: 1px solid $white-color;
          }
        }
      }
    }

    .right-part {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      float: right;
    }

    .social-list {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      li {
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }
      }

      a {
        display: inline-block;
        width: 30px;
        height: 30px;
        text-align: center;
        position: relative;
        line-height: 0;
        font-size: 20px;
        color: $white-color;
        -webkit-transition: .3s ease-out;
        transition: .3s ease-out;
        background: $primary-color;

        &:hover {
          opacity: 0.7;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          -o-object-fit: contain;
          object-fit: contain;
        }
      }
    }

  }

  .pdf-footer-2 {
    text-align: center;
    background-color: $primary-color;
    padding: 0 0 20px 0;
    position: relative;
    max-width: 1380px;
    margin: 0 auto;
    border-color: $primary-color;
    border: none;

    a {
      font-size: 20px;
      font-weight: bold;
      color: $white-color;
      letter-spacing: 1px;
    }

    .copyright-text {
      position: absolute;
      bottom: 22px;
      right: 175px;
      color: $white-color;
    }
  }
}

.buying-header {
  height: 82px;

  .object-picker {
    // box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 3px 2px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px -2px 3px 2px;
    padding: 5px;
    border-radius: 12px;

    &.active{
       transition: transform 1s;
       transform-style: preserve-3d;
       transform: rotateX(180deg);
    }

    img {
      width: 82px;
      height: 57px;
      object-fit: cover;
      border-radius: 10px;
       transform: rotateX(180deg);
    }
  }
  

  .fa-caret-down {
    color: $primary-color;
    font-size: 18px;
  }

  .site-menu,
  .buying-menu {
    width: 30%;
    justify-content: space-around;

    a {
      font-size: 13px;
      cursor: pointer;
      border-bottom: 2px solid transparent;

      &.active {
        border-bottom: 2px solid $primary-color;
      }
    }
  }

  .site-menu {
    a {
      color: $secondary-color;

      &:hover {
        color: $immozy-lightblue;
        border-bottom: 2px solid $immozy-lightblue;
      }

      &.active{
        color: $primary-color;
      }
    }
  }

  .buying-menu {
    .fa-caret-right {
      font-size: 26px;

      &.disabled{
        color: $border-grey;
      }
    }

    a {
      color: $immozy-dark;

      &.active{
         font-weight: $font-bold;
      }

      &.disabled {
        color: $border-grey;
      }

      &:hover {
        border-bottom: 2px solid $primary-color;
      }
    }

    h2{
      color: $white-color;
      user-select: none;
    }

    .fa-check{
      color: #97D887;
      margin-left: 5px;
      font-size:15px;
    }
  }
}

.onb-warning-container {
  .contact-pop-up-wrapper {
    height: 85vh;

    .left-pannel {
      width: 35%;
      padding: 50px;

      .left-section {
        height: 100%;
        
        h1 {
          font-size: 55px;
          line-height: 60px;
          font-weight: $font-bold;
        }
        p {
          width: 72%;
          margin-top: 4%;
          line-height: 18px;
        }

        .contact-btn {
          svg {
            transform: rotate(180deg);
            height: 25px;
          }
          img {
            height: 25px;
            margin-left: 5px;
            margin-top: -2px;
          }
        }
      }
    }
    .right-pannel {
      width: 65%;
      padding: 0 !important;

      .right-section {
        img {
          width: 100%;
        }
      }
    }
  }
}
