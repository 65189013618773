@import "~assets/css/variable.scss";

.welcome-container {
  overflow-x: hidden;

  &.from-popup {
    height: 695px;
    ;
    overflow-y: scroll;
    overflow-x: hidden;

    .question-container .options-container {
      margin-top: 40px;
    }

    .question-text {
      height: 80px;
    }

    .header-bar img {
      position: absolute;
    }

    .action-section.show-up {
      margin-top: 3%;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 18px;
      background-color: $white-color;
      border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb {
      height: 6px;
      background-clip: padding-box;
      border-radius: 10px;
      background-color: $white-color;
    }

    &::-webkit-scrollbar-thumb {
      height: 6px;
      background-clip: padding-box;
      border-radius: 10px;
      background-color: $primary-color;
    }
  }

  .header-bar {
    padding: 0 25px;
    margin-top: 30px;
    margin-bottom: 40px;

    img {
      width: 77px;
    }
  }

  .mobile-header-bar,
  .mobile-prev-button,
  .skip-button {
    display: none;
  }

  .onboarding-button-white {
    background: $white-color;
    border: 1px solid #caced2;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02);
    border-radius: 40px;
    width: 221px;
    height: 48px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: $font-bold;

    &:hover {
      border-color: $hover-red;
      color: $hover-red;
    }

    &:active {
      color: $primary-color;
      border-color: $primary-color;
    }
  }

  .ralf-section {
    flex-direction: column;

    .ralf-title {
      font-size: 36px;
      line-height: 48px;
      font-weight: $font-bold;
      margin: 20px 0;
    }
  }

  .ralf-subtitle {
    font-size: 18px;
    line-height: 28px;
    font-weight: $font-regular;
    color: rgba(0, 0, 0, 0.45);
  }

  .welcome-info {
    width: 50%;
    max-width: 883px;
    border: 1px solid $primary-color;
    border-radius: 20px;
    margin: 0 auto;
    padding: 1% 2%;
    margin-top: 20px;

    p {
      text-align: center;
    }

    .info-1 {
      color: $black-color;
      margin-bottom: 15px;
      font-weight: $font-bold;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.012em;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    .info-2 {
      line-height: 27px;
    }
  }

  .onboarding-progress {
    height: 4px;
    background-color: #d8d8d8;
    width: 65%;
    margin-bottom: 30px;
  }

  .progress-bar {
    background-color: $primary-color !important;
  }

  .question-text {
    font-size: 36px;
    line-height: 48px;
    font-weight: $font-bold;
    text-align: center;
    color: $black-color;
    width: 65%;
    margin: 0 auto;
    height: 80px;
  }

  .question-container {
    margin-top: 15px;

    .options-container {
      margin: 0 auto;
      width: 65%;
      margin-top: 50px;
      min-height: 300px;

      &.wrapped {
        max-width: 485px;
      }

      .options {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 145px;
        animation: options 0.4s backwards;

        &.selected {
          .options-rect {
            border: 1px solid $primary-color;

            svg {
              path {
                fill: $primary-color;
                opacity: 1;
              }
            }
          }
        }
      }

      .options-rect {
        height: 80px;
        width: 80px;
        background-color: #f6f6f8;
        border: 1px solid #caced2;
        border-radius: 8px;
        transition: all 0.3s ease;

        .ans-icon {
          width: 56px;
        }

        svg {
          path {
            fill: #000;
            opacity: 0.65;
          }
        }
      }

      .options-text {
        font-weight: $font-regular;
        font-size: 16px;
        line-height: 24px;
        color: #000;
        margin-top: 15px;
      }

      .image-options {
        width: 100%;
        margin: 0 20px;

        .img-anim {
          animation: options 0.4s backwards;
          margin: 0 20px;
        }

        .column-wrapper {
          border-radius: 17px;
          box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.14);
          overflow: hidden;
          cursor: pointer;
          background: $white-color;
          margin: 5px;
          position: relative;
          width: 210px;
          height: 210px;

          &.selected {
            .card-desc {
              background: $primary-color;
              color: $white-color;
            }
          }

          .card-img {
            width: 100%;
            height: 157px;
          }

          .card-desc {
            height: 53px;
            background: #efefef;

            h2 {
              font-size: 18px;
              line-height: 14px;
              font-weight: $font-bold;
            }
          }
        }
      }

      .option-range {
        width: 398px;
        margin: 0 auto;

        &.show-meter {
          .input-range__label-container {
            &::after {
              position: absolute;
              content: "\33A1";
              right: -17px;
              bottom: 0px;
              font-family: $font;
            }
          }
        }

        .input-range {
          width: 80%;
          margin: 0 auto;
          margin-top: 40px;

          .input-range__slider {
            background: $primary-color;
            border: 1px solid $primary-color;
          }

          .input-range__track {
            height: 8px;
          }

          .input-range__track--active {
            background: $primary-color;
          }

          .input-range__label-container {
            font-weight: $font-bold;
            font-size: 14px;
            color: rgba(79, 89, 108, 1);
            font-family: $font;
          }

          .input-range__label--min,
          .input-range__label--max {
            top: -2px;
            font-size: 14px;

            .input-range__label-container {
              font-weight: $font-light;
            }
          }

          .input-range__label--min {
            margin-left: -35px;
          }

          .input-range__label--max {
            margin-right: -30px;
          }

          .input-range__label--value {
            top: -46px;
          }
        }
      }

      .option-input {
        width: 256px;
        margin: 0 auto;

        input {
          width: 100%;
          // height: 35px;
          border: 1px solid #ced4da;
          font-size: 30px;
          font-weight: bold;
          color: #495057;
          // padding: 0.375rem 0.75rem;

          &:focus {
            outline: #495057;
          }
        }
      }
    }

    .action-section-1 {
      width: 100%;
      position: absolute;
      bottom: 7%;

      .onboarding-button-sm {
        position: relative;
        float: right;
        margin-right: 10%;
      }

      .prev-button {
        position: relative;
        margin-left: 10%;

        font-size: 16px;
        line-height: 22px;
        color: $grey-for-button;
        font-weight: $font-regular;
        width: 111px;
        height: 48px;

        &:hover,
        &:active {
          color: #000;
          border-radius: 40px;
          font-weight: $font-regular;
        }

        &:hover {
          background: #fafafa;
        }

        &:active {
          background: #f5f5f5;
        }
      }
    }


    .action-section {
      width: 65%;
      margin: 0 auto;
      margin-top: 4%;

      // &.show-up {
      //   margin-top: -1%;
      // }
      .prev-button {
        font-size: 16px;
        line-height: 22px;
        color: $grey-for-button;
        font-weight: $font-regular;
        width: 111px;
        height: 48px;

        &:hover,
        &:active {
          color: #000;
          border-radius: 40px;
          font-weight: $font-regular;
        }

        &:hover {
          background: #fafafa;
        }

        &:active {
          background: #f5f5f5;
        }
      }
    }
  }

  .fin-onb-form {
    width: 40%;
    margin: 0 auto;

    input {
      height: 50px;
      -moz-appearance: textfield !important;

      &.uppercase {
        text-transform: uppercase;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .finance-options-container {
    margin: 0 auto;
    margin-top: 20px;
    min-height: 270px;
    width: 65%;

    .check-option {
      background: $white-color;
      border: 2px solid rgba(147, 40, 73, 0.4);
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: 72px;
      width: 45%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 30px;
      cursor: pointer;

      .checkmark {
        display: none;
      }

      &.active {
        position: relative;
        border: 2px solid $primary-color;

        .checkmark {
          display: unset;
          position: absolute;
          right: 5px;
          top: 5px;
        }
      }

      &:first-child,
      &:nth-child(3) {
        margin-right: 30px;
      }
    }

    .option-input {
      width: 256px;
      margin: 0 auto;

      input {
        width: 100%;
        height: 35px;
        border: 1px solid #ced4da;
        font-size: 14px;
        color: #495057;
        padding: 0.375rem 0.75rem;
        outline: none;
        font-weight: $font-bold;
      }
    }

    .checkbox-op-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      height: 35px;
    }

    .textarea-input {
      border: 1px solid #caced2;
      border-radius: 4px;
      height: 80px;
      width: 90%;
      outline: none !important;
    }

    .empty-check {
      height: 20px;
      width: 20px;
      border: 1px solid #d8d8d8;
      border-radius: 50%;
      margin: 4px 2px;
      cursor: pointer;
    }

    .completion-steps-checkmark-wrapper {
      margin-bottom: 60px;

      .step {
        margin-bottom: 8px;

        &>svg {
          margin-right: 16px;
        }

        .step-text {
          margin: 0 12px;
        }
      }
    }
  }
}

.finance-title {
  font-weight: $font-bold;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.01em;
  font-size: 32px;
  color: $primary-color;
  margin-bottom: 2%;
}

.finance-onb {
  height: 695px;
  max-height: 750px;
  overflow-y: auto;

  .show-margin {
    margin-bottom: 30px;
  }

  .question-text {
    margin-bottom: 3%;
    height: 80px;
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 18px;
    background-color: $white-color;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: $white-color;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: $primary-color;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.onboarding-button-primary {
  width: 156px;
  height: 48px;
  left: 642px;
  top: 775px;
  background: $primary-color;
  border-radius: 40px;
  color: $white-color;

  &:hover {
    background: $hover-red;
  }
}

.onboarding-button-sm {
  width: 111px;
  height: 48px;
  background: #f5f5f5;
  border: 1px solid #caced2;
  box-sizing: border-box;
  border-radius: 40px;
  color: #caced2;

  &:hover,
  &:active,
  &.active {
    background: $primary-color;
    color: $white-color;
    border-color: $primary-color;
  }
}

@media only screen and (min-height: 900px) {
  .welcome-container {
    .action-section {
      &.show-up {
        margin-top: 2%;
      }
    }
  }
}


@media only screen and (max-width: 1600px) {
  .welcome-container .onboarding-progress {
    max-width: 50%;
    margin-bottom: 2%;
  }
}


@media only screen and (max-height: 865px) {
  .welcome-container {
    .from-popup {
      height: 90vh;
    }

    .question-container .options-container {
      min-height: 235px !important;
    }

    .action-section {
      &.show-up {

        &.more {
          margin-top: -5%;
        }
      }
    }
  }
}

@media only screen and (max-height: 768px) {
  .welcome-container {
    margin-bottom: 20px;
    overflow-y: auto;

    &.from-popup {
      margin-bottom: 0;
      height: 90vh;
    }

    .header-bar {
      height: 30px;
      margin: 15px;
      margin-bottom: 0px;

      img {
        width: 60px;
      }
    }

    &.from-popup {
      .question-text {
        height: 60px;
      }
    }

    .question-text {
      width: 80%;
      margin-bottom: 10px !important;
      font-size: 32px;
      line-height: 36px;
    }

    .question-container .options-container {
      min-height: 210px;
      width: 65%;

      .image-options {
        .column-wrapper {
          height: 180px;
          width: 180px;

          .card-img {
            height: 127px;
          }
        }
      }

      .options {
        width: 140px;
      }
    }

    .action-section {
      &.show-up {
        margin-top: -1%;

        &.more {
          margin-top: -6%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .welcome-container .onboarding-button-white {
    width: 160px;
  }
}

@media screen and (min-width: 600px) {
  .welcome-container {
    .mobile-header-bar {
      display: none !important;
    }

    .welcome-info {
      width: 70% !important;
    }
  }
}

@media only screen and (max-width: 599px) {
  .welcome-container .question-container .options-container .option-input {
    min-width: 100%;
  }

  .welcome-container {
    padding: 15px 25px;

    .header-bar,
    .prev-button {
      display: none !important;
    }

    .mobile-header-bar,
    .mobile-prev-button,
    .skip-button {
      display: unset !important;
      color: #caced2;
      font-weight: $font-regular;
    }

    .mobile-header-bar {
      height: 80px;

      img {
        width: 35px;
      }
    }

    .ralf-section {
      text-align: center;
      margin-top: 30px;

      .mobile-prev-button {
        width: 16px;
        position: absolute;
        margin-top: -74px;
        left: 15px;
      }

      .ralf-photo {
        width: 40px;
      }

      .ralf-title {
        font-size: 24px;
        line-height: 36px;
      }

      .ralf-subtitle {
        font-size: 16px;
        line-height: 21px;
      }

      .onboarding-progress {
        width: 90%;
        max-width: 90%;
      }
    }

    .welcome-info {
      width: 100% !important;
      padding: 3%;

      .info-1 {
        font-size: 14px;
        line-height: 11px;
      }

      .info-2 {
        font-size: 13px;
        line-height: 19px;
      }
    }

    .question-text {
      width: 100%;
      font-size: 22px;
      line-height: 36px;
      margin-bottom: 0px !important;
    }

    .question-container {
      .options-container {
        width: 100%;

        .check-options {
          flex-wrap: wrap;
          padding: 2%;
          justify-content: space-around !important;
        }

        .image-options {
          margin: 5% 5px;

          .img-anim {
            margin: 0;
          }

          .column-wrapper {
            height: 125px;
            width: 125px;
            border-radius: 8px;

            .card-img {
              height: 100px;
            }

            .card-desc {
              height: 26px;

              h2 {
                font-size: 12px;
              }
            }
          }
        }

        .option-range {
          width: 308px;

          .input-range {
            width: 75%;
          }
        }
      }

      .action-section {
        max-width: 100%;
        width: 85%;
        margin-top: 5%;

        &.absolute {
          position: absolute;
          bottom: 3%;
        }
      }

      .action-section-1 {
        &.relative {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .onboarding-button-sm {
          margin-top: -10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 365px) {
  .welcome-container .question-container .action-section {
    position: relative;
  }
}

@media only screen and (max-height: 450px) {
  .welcome-container .question-container .action-section.absolute {
    display: none !important;
  }
}

@media only screen and(min-width: 631px) {

  .mobile-skip,
  .progress-item-mobile,
  .mobile-tips-section,
  .thanks-page-mobile {
    display: none;
  }
}

.thanks-page-wrapper {
  height: auto;
  background: url("../../assets/images/Hamburg-city.jpeg") no-repeat center;
  background-size: cover;
  min-height: 100vh;
  padding: 15px;
  text-align: center;
}

@media only screen and (max-width: 630px) {
  .thanks-page {
    display: none;
  }

  .thanks-page-mobile {
    display: unset;
    max-width: 55%;
  }
}

.thanks-page-mobile {
  display: unset;
  max-width: 55%;

  &.question-text {
    font-size: 20px !important;
  }

  .bottom-section {
    margin-top: 180px;
  }
}

.onboarding-spinner-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.thank-you-wrapper {
  width: 60%;
  border-radius: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 15px auto;

  .chat-icon {
    margin: 25px 0;

    .chat-icon-lg {
      width: 207px;
      height: 203px;
    }
  }

  .thank-you-heading {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 30px;
  }

  .thank-you-sub-text {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 43px;
  }
}

.close-btn-fin {
  position: absolute;
  right: 15px;
  top: 15px;
}