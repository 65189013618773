/**
* Template Name: WeBuild - v2.0.0
* Template URL: https://bootstrapmade.com/free-bootstrap-coming-soon-template-countdwon/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@import "variable.scss";

:root {
  --app-primary-color: rgba(147, 40, 73, 1);
  --app-secondary-color: rgba(55, 75, 106, 1);
}

// $primary-color: rgba(147, 40, 73, 1);
// $secondary-color: rgba(55, 75, 106, 1);
// $immozy-dark: rgba(48, 56, 71, 1);
// $immozy-lightblue: rgba(160, 172, 206, 1);

body {
  font-family: $font;
  color: #444;
}

a {
  color: $primary-color;

  &:hover {
    text-decoration: none;
    color: $immozy-lightblue;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-primary {
  font-family: $font;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  width: $full-width;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: $white-color;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 19px 0px;

  .container-fluid {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
  }
}

.blackout-div {
  position: fixed;
  top: 80px;
  left: 0px;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.1);
  z-index: 10001;
}

.header .header {
  &:before {
    content: "";
    width: $full-width;
    height: $full-height;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .logo h1 {
    font-size: 36px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: $font-light;
    letter-spacing: 3px;
  }

  .logo h1 a,
  .logo h1 a:hover {
    color: $white-color;
    text-decoration: none;
  }

  .logo img {
    padding: 0;
    margin: 0;
    width: $full-width;
    height: $full-height;
  }

  .logo {
    max-width: 370px;
    height: $full-height;
    display: inline-block;
  }

  .container-fluid {
    height: $full-height;
    box-shadow: 0px 2px 19px 0px rgba(0, 0, 0, 0.15);
  }
}

.logo-img {
  margin: 5px 0 5px 42px;
  width: 65px;
}

.close-btn-div {
  position: absolute;
  height: 26px;
  width: 26px;
  right: 15px;
  top: 15px;
  background-repeat: no-repeat;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #979797;
  line-height: 10px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s ease;
  z-index: 5;
  text-align: center;

  svg {
    width: 12px;
    transition: 0.3s ease;
    fill: $grey-light;
    font-weight: $font-bold;
  }

  &:hover {
    box-shadow: 0px 0 4px 1px rgba(0, 0, 0, 0.1);

    svg {
      fill: $primary-color;
    }
  }

  &.active {
    background-color: $primary-color;
    border-color: $primary-color;
    box-shadow: none !important;

    svg {
      fill: $white-color;
    }

    &:hover {
      svg {
        fill: $white-color;
      }
    }
  }
}

.profile-icon-wrapper a {
  color: $white-color;
  font-size: 1.1rem;
  cursor: pointer;
}

.profile-icon-wrapper a > a {
  color: #000;
  font-size: 1rem;
}

.profile-icon-wrapper label {
  color: $white-color;
}

.profile-icon-wrapper i {
  cursor: pointer;
}

@-webkit-keyframes animate-loading-notify {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading-notify {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/

.section-bg {
  background-color: #f7fafc;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: $font-light;
  margin-bottom: 20px;
  padding-bottom: 0;
}

.section-title p {
  margin-bottom: 0;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.no-gutters {
  margin-right: 10px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background-color: #4e0b20;
  padding: 10px 0;
  height: 272px;

  .container-div-footer {
    padding: 30px 50px;
    letter-spacing: 0.07em;
    line-height: 28px;
    color: $white-color;
    font-size: 14px;
    max-width: 1920px;
    margin: 0 auto;
  }

  .copy-right {
    text-align: center;
    letter-spacing: 0.06em;
    line-height: 14px;
    color: $white-color;
    font-size: 14px;
    font-weight: $font-light;
  }

  .social-div {
    float: right;
    margin-right: 50px;
    display: none;
  }

  .fb {
    width: 50px;
    margin-right: 20px;
  }

  .instagram {
    width: 50px;
  }

  .first-row {
    margin-right: 5%;

    li {
      a {
        transition: all 0.3s ease;
        text-decoration: none;
        font-weight: $font-light;

        // &:hover,
        // &:focus,
        // &:visited {
        //   text-decoration: underline;
        // }
      }
    }
    .foooter-bold {
      font-weight: $font-bold;
    }

    .footer-grey-link {
      color: $border-grey !important;
      &:hover {
        color: $white-color !important;
      }
    }
  }
}

.pdf {
  font-size: 25px;
  color: red;
  cursor: pointer;
}
.admin-conatainer {
  min-height: 100vh;
  padding: 20px 0;
  background: url("../images/Hamburg-city.jpeg") no-repeat center;
  background-size: cover;

  .admin-login-label {
    text-align: center;
    margin-top: 3%;
    color: $white-color;
  }
}
