@import "~assets/css/variable.scss";

.properties-table {
  width: 100%;
}

.overflow-table {
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.properties-table {
  th,
  td {
    border: 1px solid #cecece;
    padding: 5px;
  }

  td {
    &:nth-child(1) {
      width: 53%;
    }

    &:nth-child(2) {
      width: 10%;
    }
  }
}

.com-div {
  i {
    margin-top: 5px;
    background: #cecece;
    color: white;
    font-size: 12px;
    height: 21px;
    width: 21px;
    text-align: center;
    border-radius: 21px;
    line-height: 22px;

    &.active {
      border: 2px solid $primary-color;
      line-height: 18px;
      background: $secondary-color !important;
    }
  }
}

.hide-scroll {
  overflow-y: hidden !important;
}

.custom-properties-div {
  position: relative;
  max-width: 1180px;
  width: 100%;
  margin: 15px 0;
  background-color: $white-color;
  border-radius: 12px;
  max-height: 550px;
  padding: 30px;

  .section-title {
    h6 {
      position: relative;
    }

    .spinner-wrapper {
      position: absolute;
      right: -45px;
      top: -8px;
    }
  }

  .prop-popup {
    position: relative;
    height: 550px;
    max-width: 1180px;
    width: 100%;
    margin: 15px 0;
    background-color: $white-color;
    border-radius: 12px;
  }

  .model-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.25);
    transition: all 0.4s ease;
    z-index: 100;
    opacity: 0;
    z-index: -1;
    padding: 80px 50px 50px;
    margin: 0;
    border: 1px solid grey;

    &.show {
      opacity: 1;
      z-index: 5;
    }
  }
}

.custom-prop-item {
  border: 1px solid #cecece;
  padding: 15px;
  width: 90%;
  border-radius: 8px;
  height: 61px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);

  p {
    width: 85%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.custom-prop-icons {
  i {
    border-radius: 50%;
    padding: 6px;
    margin: 3px;
    color: white;
    font-size: 14px;
    cursor: pointer;
  }

  .fa-pencil,
  .fa-eye {
    background-color: $secondary-color;

    &:hover {
      background-color: #8693ac;
    }
  }

  .fa-trash {
    background-color: $primary-color;

    &:hover {
      background-color: #c41c50;
    }
  }

  .fa-heart,
  .fa-heart-o {
    background-color: $primary-color;

    &:hover {
      color: $white-color;
    }
  }
}

.prop-list {
  animation: options 0.4s backwards;
}

.custom-thumbnail {
  margin-right: 20px;

  img {
    height: 58px;
    width: 58px;
    border-radius: 8px;
    border: 1px solid #cecece;
  }
}

.overflow-table,
.general-main-div {
  &::-webkit-scrollbar {
    width: 10px;
    height: 18px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: #cecece;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.properties-table,
.overflow-table,
.add-prop-main {
  scroll-behavior: smooth;

  .general-main-div {
    padding-top: 30px;

    height: 380px;
    overflow: auto;
  }
}

.add-prop-main {
  .title-add {
    text-align: center;
    text-transform: capitalize;
    font-size: 16px;
    color: $grey-light;
    letter-spacing: 0.06em;
    line-height: 18px;
    font-weight: $font-bold;
    padding: 15px 0px;
  }

  .add-prop-desc {
    margin-left: 15px;
    margin-bottom: 25px;
    font-size: 14px;
    color: $grey-light;
  }

  .modal-btn {
    width: 207px !important;
    font-size: 15px !important;
  }

  .nav-link {
    display: inline;

    &.active {
      border-bottom: none !important;
      color: $primary-color;
    }
  }

  .nav-tabs {
    border: none;
    padding-left: 45px;

    .nav-item {
      margin-bottom: 5px !important;
    }
  }

  .custom-prop-link {
    position: relative;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: $border-grey !important;

    .bottom-effect {
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      line-height: 0;
      text-align: center;

      span {
        display: inline-block;
        height: 3px;
        width: 100%;
        max-width: 55px;
        margin: 0 auto;
        // background-color: $primary-color;
      }
    }

    &.active-link {
      color: $primary-color !important;
      position: relative;

      .bottom-effect {
        span {
          background-color: $primary-color;
        }
      }
    }

    &.active {
      border-bottom: none !important;
      color: $primary-color !important;
    }
  }

  .add-prop-next {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    background-color: transparent;

    .image-gallery-icon .image-gallery-svg {
      border: 1px solid #cecece;
      transition: all 0.3s ease;

      &:hover {
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0) !important;
      }
    }

    .image-gallery-left-nav,
    .image-gallery-right-nav {
      transition: opacity 0.5s ease;
      padding: 0 20px;
      margin-top: 5px;

      .image-gallery-svg {
        height: 45px;
        color: $black-color;
        width: 45px;
        background-color: $white-color;
        border-radius: 50%;
        outline: none;

        &:active {
          color: $primary-color !important;
        }
      }
    }
  }

  .right-side-check {
    .custom-checkbox {
      width: 100%;
      padding-left: 0;

      .custom-control-label {
        width: 100%;

        &::before,
        &::after {
          left: auto;
          right: -35px;
        }
      }
    }
  }
}

.hr-clr {
  background-color: var(--app-primary-color);
}

.number-clr {
  background-color: #495057;
  padding: 5px;
  margin-left: 3px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
}

.dot-class {
  height: 29px;
  width: 29px;
  background-color: $border-grey;
  border-radius: 29px;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  color: $white-color;
  pointer-events: none;
  line-height: 29px;
  font-weight: $font-bold;

  &.active {
    background-color: $primary-color;
  }
}

.general-main-div {
  padding-left: 30px;

  .form-check-label {
    line-height: 30px;
  }

  label {
    color: $grey-light;
    font-size: 14px;
  }

  .form-group {
    margin-left: 0px;
  }

  .form-control {
    width: 196px;
    border-radius: 7px;
    margin-left: 25px;

    &:focus {
      border: 1px solid $primary-color;
    }
  }

  input[type="checkbox"] {
    border: 1px solid $border-grey !important;
    border-radius: 8px !important;
    height: 28px;
    width: 28px;
  }

  select:required:invalid {
    color: gray;
  }

  option[value=""][disabled] {
    display: none;
  }

  option {
    color: black;
  }
}

.checkbox-align {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  // margin-right: 16px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  text-transform: capitalize;
}

.close-icon {
  position: absolute;
  right: 15px;
  top: 5px;
}

.spinner-div {
  height: 70px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.clear-btn {
  font-size: 14px;
}

.fileinput {
  .form-control {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.upload-file-wrapper {
  width: 75px;
  height: 75px;
  padding: 17px 5px;
  border: 1px solid #e7eaec;
  position: relative;
  margin-left: 15px;
  margin-top: 10px;
}

.upload-attachments-wrapper {
  .upload-file-wrapper {
    button {
      width: 26px;
      height: 26px;
      background-color: #d84747;
      border: 0;
      border-radius: 50%;
      position: absolute;
      top: -10px;
      right: -10px;
      padding: 4px;
      outline: none;

      svg {
        width: 10px;
        height: 10px;
        margin-bottom: 10px;
      }
    }

    label {
      width: 100%;
      height: 100%;
      line-height: 60px;
      cursor: pointer;

      abbr {
        height: 100%;
        cursor: pointer !important;
      }
    }
  }

  svg {
    width: 95px;
    height: 95px;
    margin-bottom: 5px;

    path {
      fill: white;
    }
  }

  span {
    font-size: 14px;
  }
}

.fa-upload {
  font-size: 40px;
  color: var(--app-secondary-color);
  cursor: pointer;
}

.fa-upload-disabled {
  color: #cecece !important;
}

.preview-img {
  width: 73px;
  height: 73px;
  margin-top: -15px;
  margin-left: -3px;
}

.attachments-note {
  .note-text {
    font-size: 11px;
    color: $grey-light;
    line-height: 12px;
  }

  span {
    display: inline-block;
    vertical-align: bottom;
  }
}

.hide {
  display: none !important;
}

.badg {
  font-weight: 600;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;
  margin-top: 5px;
  text-shadow: none;
  font-size: 14px;
  background-color: var(--app-primary-color);
}

.disable-section {
  pointer-events: none !important;
  opacity: 0.5;
}

.succs-msg {
  font-size: 14px;
  color: $white-color;
  background: var(--app-secondary-color);
  padding: 4px 10px;
  margin-left: 15px;
  border-radius: 4px;
}

.pdf-section {
  input[type="file"] {
    padding: 3px;
  }
}

.field-err {
  color: #d11b1b;
  font-size: 14px;
  font-weight: $font-bold;
  text-align: center;
}

.search-input-container {
  display: inline !important;

  .suggestion-input {
    border: none !important;
    font-size: 14px !important;
    margin-left: 5px;
    width: 230px !important;
    background-color: transparent;
    text-overflow: hidden !important;
  }
}

.suggestions-container {
  overflow-x: hidden !important;
  max-height: 260px !important;
}

.info-icon1 {
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
  color: var(--app-primary-color);
}

.suggestions-container {
  ul {
    li {
      span {
        text-transform: capitalize !important;
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .properties-table {
    td {
      &:nth-child(2) {
        width: 11%;
      }
    }
  }

  .clear-btn {
    height: 35px;
  }
  .general-main-div {
    .search-input-container {
      .suggestion-input {
        width: 230px !important;
        text-overflow: ellipsis !important;
      }

      .search-input-container__inner {
        width: 263px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .succs-msg {
    margin-left: 5px !important;
  }
}

.scroll-dv {
  overflow-y: scroll;
  height: 550px;
}

.prev-question-btn {
  left: 34% !important;
  bottom: 5px;
}

.custom-select-form {
  position: relative;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  color: #495057;
  background-color: $white-color;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  .arrow-div {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 25px;
    width: 25px;
    color: white;
    background: $primary-color;
    font-size: 20px;
    line-height: 25px;
    border-radius: 50%;
    text-align: center;
  }

  .custom-options-wrapper {
    position: absolute;
    top: 56px;
    right: -140px;
    max-height: 185px;
    width: 236px;
    background-color: $white-color;
    border-radius: 20px;
    box-shadow: 0px 4px 11px 4px rgba(0, 0, 0, 0.15);
    //  0px 8px 16px 8px rgba(0, 0, 0, 0.15);
    z-index: 2;
    display: none;
    padding-top: 10px;

    &::before {
      content: "";
      position: absolute;
      top: -19px;
      left: 53px;
      border-bottom: 20px solid $white-color;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
    }

    &.show {
      display: block;
    }

    .custom-options {
      overflow-x: hidden;
      overflow-y: scroll;
      max-height: 170px;
      padding-bottom: 5px;
      cursor: pointer;
      margin-right: 15px;

      &::-webkit-scrollbar {
        width: 7px;
        height: 18px;
      }

      &::-webkit-scrollbar-thumb {
        height: 6px;
        background-clip: padding-box;
        border-radius: 10px;
        background-color: #cecece;
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
      }
    }

    li {
      padding: 5px 15px;
      transition: all 0.3s ease;
      margin-left: 5px;

      &:hover {
        background-color: $bg-white;
      }
    }
  }
}
