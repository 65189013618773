@import "~assets/css/variable.scss";

.contact-btn {
    background-color: $primary-color;
    color: $white-color !important;
    font-weight: bold;
    letter-spacing: 0.5px;
    width: 100%;
    border-radius: 50px;
    height: 64px;
    transition: 0.2s background-color ease-in-out;

    &:hover {
        background-color: $hover-red;
    }

    &:disabled{           
        background-color: #e9ecef;
        cursor: not-allowed;
    }
}

.pdf-wrapper {

    * {
        color: black;
    }

    .pdf-header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 15px;

        img {
            width: 120px;
        }

        h2 {
            color: black;
            font-weight: bold;
        }
    }

    .pdf-content {
        padding: 0 50px;

        .contact-detail-wrapper {
            padding: 10px 15px;
            border: 1px solid #C5C5C5;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 15px;

            .col {
                min-width: calc(25% - 10px);
                max-width: calc(25% - 10px);
                margin: 5px;
                padding: 0 5px;

                .name {
                    color: $primary-color;
                    font-weight: bold;
                }

                &:nth-child(5) {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

            }

            .progress-wrapper {
                width: 40px;
                margin-right: 10px;
            }

            .progress-percentage {
                color: #000;
                font-weight: bold;
                font-size: 20px;
            }
        }

        .finance-onboarding-detail-wrapper,
        .onboarding-detail-wrapper {
            margin-bottom: 15px;

            .finance-onboarding-header,
            .onboarding-header {
                padding: 5px 20px;
                border: 1px solid #C5C5C5;
                border-radius: 10px;
                position: relative;
                z-index: 1;

                p {
                    padding: 0;
                    color: $primary-color;
                    font-weight: bold;
                    font-size: 20px;
                    letter-spacing: 0.5px;
                }
            }

            .finance-onboarding-content-wrapper,
            .onboarding-content-wrapper {
                padding: 0 5px 5px 5px;
                position: relative;
                z-index: 0;

                .finance-onboarding-content,
                .onboarding-content {
                    border: 2px dashed #D8D8D8;
                    border-radius: 0 0 10px 10px;
                    margin-top: -2px;
                    border-top: none;
                    background: #F8F8F8;
                    padding: 20px;

                    table {
                        width: 100%;
                        border-collapse: separate;
                        border-spacing: 0 13px;

                        th {
                            width: 40%;
                        }


                        ul {
                            li {
                                list-style: disc;
                            }
                        }


                        * {
                            vertical-align: baseline;
                        }
                    }
                }
            }
        }
    }

    .pdf-footer {
        background-color: $primary-color;
        padding: 30px 100px 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-color: $primary-color;
        margin: 0;
        position: relative;
        z-index: 5;
        border: none;

        .col {
            width: 50%;
            float: right;
        }

        table {
            border-collapse: separate;

            th,
            td {
                &:first-child {
                    width: 200px;
                }

                color: $white-color;

                a {
                    color: $white-color;

                    &:hover {
                        border-bottom: 1px solid $white-color;
                    }
                }
            }
        }

        .right-part {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            float: right;
        }

        .social-list {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            li {
                margin-right: 40px;

                &:last-child {
                    margin-right: 0;
                }
            }

            a {
                display: inline-block;
                width: 30px;
                height: 30px;
                text-align: center;
                position: relative;
                line-height: 0;
                font-size: 20px;
                color: $white-color;
                -webkit-transition: .3s ease-out;
                transition: .3s ease-out;
                background: $primary-color;

                &:hover {
                    opacity: 0.7;
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    -o-object-fit: contain;
                    object-fit: contain;
                }
            }
        }

    }

    .pdf-footer-2 {
        text-align: center;
        background-color: $primary-color;
        padding: 0 0 20px 0;
        position: relative;
        margin: 0;
        border-color: $primary-color;
        border: none;

        a {
            font-size: 20px;
            font-weight: bold;
            color: $white-color;
            letter-spacing: 1px;
        }

        .copyright-text {
            position: absolute;
            bottom: 22px;
            right: 175px;
            color: $white-color;
        }
    }
}

footer {
    position: fixed;
    z-index: 3;
    bottom: 0;
    width: 100%;
    background-color: $primary-color;
}