@import "~assets/css/variable.scss";

.relative {
  position: relative;
}

.text-underline {
  text-decoration: underline;
}

.image-gallery-popup,
.cal-popup {
  &::-webkit-scrollbar {
    width: 10px;
    height: 18px;
    background-color: $white-color;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: $white-color;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: #cecece;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.object-detail {
  margin: 0 auto;

  .bg-green {
    background-color: #65c239;
  }

  .bg-red {
    background-color: $primary-color;
  }

  .bg-yellow {
    background-color: #ffbb00;
  }

  .row {
    margin: 0;
  }

  .disable-object-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    left: 0;
    background: #0000007a;
    color: white;
    text-align: center;
    padding-top: 10%;
    font-size: 25px;
    z-index: 1016;
    top: 0;
  }

  .object-image-gallery {
    padding-top: 25px;

    .row {
      position: relative;
    }

    .spin-img {
      margin-top: 15% !important;
      margin-bottom: 15% !important;
    }

    .button {
      position: absolute;
      top: 20px;
      left: 20px;
      height: 36px;
      width: 110px;
      background-color: $white-color;
      border: 1px solid $border-grey;
      border-radius: 18px;
      font-size: 11px;
      line-height: 34px;
      font-weight: $font-regular;
      transition: all 0.3s ease;

      &:hover {
        color: $primary-color;
        border-color: $primary-color;
      }
    }

    .column-wrapper {
      padding: 0;
    }

    .img-wrapper {
      position: relative;
      padding: 5px;
      border-radius: 5px;
      max-height: 650px;
      height: 262.5px;

      &::before {
        content: "";
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: transparent;
        border-radius: 5px;
        transition: all 0.3s ease;
      }

      &:hover {
        &::before {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }

      &.lg {
        height: 525px;
        max-width: 100%;

        img {
          height: 100%;
        }
      }

      img {
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 5px;
        height: 100%;
      }
    }
  }

  .object-description {
    color: $grey-light;
    padding-top: 20px;

    .column-wrapper {
      .title {
        font-size: 16px;
        font-weight: $font-bold;
        margin-bottom: 15px;
      }

      .map-icon {
        width: 20px;
        margin-right: 5px;
        margin-top: -3px;
        fill: $immozy-dark;
      }

      a {
        text-decoration: underline;
      }

      .object-status {
        font-size: 14px;

        span {
          font-size: 16px;
          font-weight: 600;
          color: $primary-color;
        }
      }

      .object-para {
        font-size: 14px;
        line-height: 21px;
        overflow: hidden;
      }

      .read-more {
        color: $primary-color;
        font-weight: $font-bold;
        cursor: pointer;
      }
    }

    .description-card {
      border-radius: 20px;
      box-shadow: 0px 1px 14px 2px rgba(0, 0, 0, 0.15);
      margin-top: -100px;
      background-color: $white-color;

      .card-item {
        padding: 25px;
        box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.1);

        &:first-child {
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }

        &:last-child {
          box-shadow: none;
        }

        .button {
          max-width: 207px;
          width: 100%;
          margin: 10px auto;
          padding: 0 15px;
          background-color: $primary-color;
          border-radius: 20px;
          color: $white-color;
          font-size: 14px;
          height: 40px;
          line-height: 40px;
          transition: all 0.4s ease;
          font-weight: 600;

          &:hover {
            background-color: $hover-red;
          }

          .contact-svg {
            fill: $white-color;
            width: 25px;
            margin-right: 5px;
          }

          &:disabled {
            opacity: 0.6;
            cursor: not-allowed;

            &:hover {
              background-color: $primary-color;
            }
          }
        }
      }

      .card-title {
        width: calc(100% - 45px);
        font-size: 18px;
        font-weight: 600;
      }

      .heart-icon {
        height: 33px;
        width: 33px;
        background-color: $white-color;
        border: 1px solid #979797;
        border-radius: 33px;
        text-align: center;
        line-height: 35px;
        cursor: pointer;

        i {
          font-size: 19px;
        }

        .fa-heart {
          color: $primary-color;
        }

        &:hover {
          color: $primary-color;

          i {
            font-weight: $font-bold;
          }
        }
      }

      .object-items {
        font-size: 14px;

        h2 {
          font-size: 16px;
          font-weight: 600;

          &.price {
            font-size: 25px;
            text-align: left !important;
            white-space: nowrap;
          }
        }

        h6 {
          font-size: 14px;
          font-weight: 600;
          color: $border-grey;
        }

        .fa-check {
          color: $primary-color;
          font-size: 18px;
          margin-right: 5px;
        }
      }

      .realtor-badge {
        position: absolute;

        &.side-badge {
          left: -3%;
          transform: translateY(155px);
        }

        &.top-badge {
          left: 42%;
          transform: translateY(-30px);
        }
      }

      .inventory-badge-class {
        top: unset;
      }
    }
  }

  .object-score {
    position: relative;
    padding: 15px;
    border: 1px solid #e9ecef;
    border-radius: 20px;

    &.disabled {
      &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(216, 216, 216, 0.4);
        z-index: 2;
        border-radius: 20px;
      }

      .object-score-overlay {
        display: block;
      }
    }

    .object-score-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      display: none;
      background-color: $white-color;
      padding: 15px;
      border-radius: 20px;
      z-index: 3;

      a {
        text-decoration: underline;
        transition: all 0.3s ease;
      }
    }

    .object-score-title {
      font-size: 14px;
      color: $primary-color;
      font-weight: 600;
    }

    .score {
      position: relative;
      height: 77px;
      width: 84px;
      text-align: center;
      line-height: 77px;
      border-radius: 8px;
      margin: 30px 0 5px;
      font-size: 35px;
      color: $white-color;
      font-weight: 600;

      .question-icon {
        position: absolute;
        right: -45px;
        top: 25.5px;
        cursor: pointer;
        width: 20px;
        text-align: center;
        font-size: 15px;
        line-height: 26px;
        // border: 1px solid #979797;
        border-radius: 50%;
        margin-left: 10px;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
        color: $grey-light;
        transition: all 0.4s ease;

        // &:hover {
        //   box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
        // }

        svg {
          fill: $secondary-color;
        }
      }
    }

    .score-label {
      font-size: 14px;
      font-weight: 600;
      width: 84px;
      text-align: center;
    }

    .score-item {
      margin-top: 20px;

      .score-item-icon {
        height: 42px;
        width: 42px;
        border-radius: 50%;
        border: 1px solid #979797;
        text-align: center;
        z-index: 1;
        background-color: $white-color;
        display: flex;
        align-items: center;
        place-content: center;

        svg {
          fill: #979797;
          width: 25px;
          height: 25px;
          margin-right: -2px;
          margin-top: 1px;
        }
      }

      .progress {
        width: calc(100% - 75px);
        background-color: #f2f2f2;
        height: 20px;
        border-radius: 8px;
        margin-left: -7px;

        .progress-bar {
          text-align: right;
          padding-right: 8px;
          font-weight: 600;
          border-radius: 8px;
          font-size: 16px;

          &.bg-success {
            background-color: #65c239 !important;
          }

          &.bg-warning {
            background-color: #ffbb00 !important;
          }

          &.bg-danger {
            background-color: #be3131 !important;
          }
        }
      }

      .score-item-title {
        font-size: 14px;
        // margin-top: 4px;
      }

      .question-icon {
        cursor: pointer;
        width: 20px;
        text-align: center;
        line-height: 26px;
        border-radius: 50%;
        margin-left: 10px;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
        transition: all 0.4s ease;

        // &:hover {
        //   box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
        // }

        svg {
          fill: $secondary-color;
        }
      }
    }
  }

  .tab-view {
    .nav-tabs {
      border: none !important;

      .nav-link {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: 8px 15px;
        background-color: $bg-white;
        margin-right: 8px;
        color: $grey-light;
        font-weight: 600;
        font-size: 14px;
        transition: all 0.4s ease;

        &:last-child {
          margin-right: 0;
        }

        &:hover,
        &.active {
          color: $primary-color;
          background-color: $white-color;
          border-bottom: 1px solid $white-color !important;

          svg {
            fill: $primary-color;
          }
        }
      }
    }

    .tab-content {
      border: 1px solid #e9ecef;
      height: calc(100% - 40px);
      border-radius: 20px;
      border-top-left-radius: 0;
      padding: 20px;

      .detail-item {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        p {
          font-size: 14px;
          color: $immozy-dark;

          span {
            font-weight: 600;
          }

          .color {
            height: 15px;
            width: 15px;
            border-radius: 4px;
            //  background-color: $primary-color;
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .extra-section {
    h2 {
      font-size: 14px;
      color: $primary-color;
      font-weight: 600;
      margin-bottom: 25px;
    }

    p {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      color: $grey-light;
      height: 32px;
      background-color: #e2e6f1;
      border-radius: 9px;
      padding: 5px 30px 5px 15px;
      margin-bottom: 5px;
      font-weight: 600;
    }
  }

  .map-location {
    h3 {
      font-size: 14px;
      font-weight: 600;
      color: $grey-light;
    }

    .map-view {
      width: 100%;
      height: 470px;

      .empty-map {
        background-color: lightgray;
        height: 100%;
      }
    }
  }

  .location-info {
    .title {
      color: $primary-color;
      font-size: 14px;
      font-weight: 600;
    }

    a {
      font-size: 14px;
      text-decoration: underline;
    }

    .detail-item {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      p {
        font-size: 14px;
        color: $immozy-dark;

        &:last-child {
          font-weight: 600;
        }
      }
    }
  }

  .model-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.25);
    transition: all 0.4s ease;
    z-index: -1;
    opacity: 0;
    padding: 80px 50px 50px;
    margin: 0;
    border: 1px solid grey;

    &.show {
      z-index: 100;
      opacity: 1;
    }

    .image-gallery-popup {
      width: $full-width;
      background-color: white;
      border-radius: 23px;
      height: 85vh;
      max-height: 800px;
      margin: 0 !important;

      .custom-filters {
        position: absolute;
        z-index: 1;
        top: 60px;
        right: 0px;
        height: 53px;
        transition: all 0.4s ease;
        border-bottom-left-radius: 27px;
        border-top-left-radius: 27px;

        &.hide-filters {
          .map-filter {
            display: none;
          }
        }

        .e {
          box-shadow: none;
        }

        .MuiGrid-container {
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;
          height: 53px;
          border-bottom-left-radius: 27px;
          border-top-left-radius: 27px;
          align-content: center;
        }

        .custom-checkbox {
          padding-left: 28px;
          min-height: auto !important;
        }

        .custom-control-label {
          padding-top: 4px;
          font-size: 10px;

          &::before {
            left: -28px;
            top: 1px;
            height: 22px;
            width: 22px;
            border-radius: 6px !important;
            border: 1px solid #979797;
            box-shadow: none !important;
          }

          &::after {
            left: -28px;
            top: 1px;
            height: 22px;
            width: 22px;
            border-radius: 6px !important;
          }
        }

        .map-icon {
          width: 23px;
          margin-right: 3px;
          fill: $grey-light;
          margin-top: -5px;
        }
      }

      &.one-image-slider {
        .image-gallery-image {
          width: auto;
          max-height: 675px;
          margin-top: 20px;
          border-radius: 5px;
          height: calc(100vh - 82px);
        }
      }
    }

    .image-gallery-thumbnails-wrapper {
      width: 60% !important;
      margin: 0 auto !important;
    }

    .close-btn-div {
      z-index: 11;
      text-align: center;
    }

    .image-gallery {
      height: 100%;

      .image-gallery-content {
        height: 100%;

        .image-gallery-slide-wrapper {
          height: calc(100% - 100px);

          .image-gallery-icon {
            border: 1px solid lightgray;
            border-radius: 50%;
            filter: none;

            .image-gallery-left-nav .image-gallery-svg,
            .image-gallery-right-nav .image-gallery-svg {
              color: #a4a4a4;
            }

            &:hover {
              border: 1px solid lightgray !important;
              filter: drop-shadow(0 2px 2px #cececc) !important;

              .image-gallery-left-nav .image-gallery-svg,
              .image-gallery-right-nav .image-gallery-svg {
                color: $primary-color !important;
              }
            }
          }

          .image-gallery-swipe {
            height: 100%;

            .image-gallery-slides {
              height: 100%;

              .image-gallery-slide {
                height: 100%;

                div {
                  height: 100%;
                }

                .image-gallery-image {
                  height: 100%;
                  width: auto;
                  max-height: 675px;
                  margin-top: 20px;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }

      .image-gallery-left-nav,
      .image-gallery-right-nav {
        padding: 0;
        margin: 50px 5%;
      }

      .image-gallery-thumbnail {
        border-radius: 5px;
        overflow: hidden;
        border: 2px solid transparent;
        width: 115px !important;

        &:hover,
        &:focus,
        &.active {
          border: 2px solid $primary-color;
        }
      }
    }
  }

  .object-tooltip {
    position: absolute;
    left: -4px;
    width: 237px;
    background-color: $white-color;
    font-size: 15px;
    text-align: center;
    font-weight: $font-regular;
    padding: 20px;
    color: $grey-light;
    box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.15);
    z-index: 11;
    border-radius: 24px;

    &.progress-tooltip {
      right: -101px;
      left: auto;
      top: 55px;
    }

    &::before {
      position: absolute;
      content: "";
      border-left: 21px solid transparent;
      border-right: 21px solid transparent;
      border-bottom: 21px solid $white-color;
      top: -21px;
      left: 97.5px;
      filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
    }
  }

  .request-doc-popup {
    position: relative;
    max-width: 595px;
    margin: 0 auto;
    background-color: $white-color;
    border-radius: 12px;
    overflow: auto;
    letter-spacing: 0.04em;

    .request-body {
      padding: 25px;
      text-align: center;

      .rq-title {
        font-size: 16px;
        color: $grey-light;
        text-align: center;
        letter-spacing: 0.06em;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        font-weight: $font-bold;
      }

      .rq-desc {
        font-size: 14px;
        color: $immozy-dark;
        line-height: 22px;
        text-align: left;
      }

      .rq-sub {
        font-size: 14px;
      }

      .rq-button {
        max-width: 240px;
        width: 100%;
        padding: 0 30px;
        background-color: $primary-color;
        border-radius: 20px;
        color: $white-color;
        font-size: 12px;
        letter-spacing: 0.06em;
        height: 40px;
        line-height: 40px;
        transition: all 0.4s ease;
        text-transform: uppercase;
        font-weight: $font-bold;

        &:hover {
          background-color: $hover-red;
        }
      }

      .rq-success {
        color: #65c239;
        font-size: 25px;

        .fa-check {
          display: inline;
        }
      }

      .rq-wait {
        font-size: 24px;
        margin-bottom: 10px;
        color: #ffbb00;
      }

      .rq-fail {
        font-size: 24px;
        margin-bottom: 10px;
        color: #d11b1b;
      }
    }

    .rq-bottom {
      margin: 0 auto;
      text-align: center;
      background-color: #d3d7e4;
      padding: 25px;

      .help-text {
        font-size: 14px;
        color: $immozy-dark;
        line-height: 21px;
      }

      .button-apply {
        width: 207px !important;
        font-weight: $font-bold !important;

        &:hover {
          border-color: $primary-color !important;
          background-color: $white-color !important;

          a {
            color: $primary-color !important;
          }
        }
      }
    }
  }
}

.gm-control-active {
  position: relative;
  background-color: $white-color !important;
  border: 1px solid $border-grey !important;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.28);

  &::before {
    position: absolute;
    content: "";
    background-size: contain;
    height: 17px;
    width: 17px;
    top: 12px;
    left: 11px;
    color: $white-color;
  }

  &:first-child {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: none !important;

    &::before {
      background-image: url("../../assets/images/BTN-plus.png");
    }
  }

  &:last-child {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    &::before {
      background-image: url("../../assets/images/BTN-minus.png");
      top: 10px;
    }
  }

  img {
    display: none !important;
  }
}

.object-data-svg {
  fill: $immozy-dark;
  width: 23px;
  margin-right: 5px;
}

.new-interested-popup {
  .success-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
  }

  .first-part {
    color: $immozy-dark;
    font-size: 14px;

    #inputID {
      height: 37px;
      width: 185px;
      font-size: 14px;
    }

    .primary-btn {
      font-size: 14px;
      min-width: 165px;
      font-weight: $font-bold;
      width: 165px;
      height: 37px;
      border-radius: 25px;
      margin: 0 auto;
      box-shadow: none !important;
      font-size: 16px;
      color: $white-color;
      text-align: center;
      letter-spacing: 1px;

      &:hover {
        background-color: #e50c4f !important;
      }
    }

    .custom-checkbox label::before {
      width: 16px;
      height: 16px;
      min-width: 16px;
    }

    // .custom-checkbox.checked label::after {
    //   left: unset;
    //   top: unset;
    //   margin-top: -32px;
    //   margin-left: -7px;
    // }

    .error-border-round {
      .custom-control-label::before {
        border: 2px solid $primary-color !important;
      }
    }

    .error-border {
      border: 2px solid $primary-color !important;
    }

    .error-contact {
      font-size: 12px;
      color: #d11b1b;
      text-align: center;
      letter-spacing: 0;
      font-weight: $font-bold;
      margin: 8px 0;
    }

    .long-desc-text {
      font-size: 10px;
    }
  }
}

// -------------------------------------------- heat styling --------------------------------------------------

.enerdy-data-container {
  border: 1px solid #e9ecef;
  border-radius: 20px;

  .heat-bar-wrapper {
    height: 55px;
    background-color: red;
    background-image: linear-gradient(to right, #00c17a, #ffc454, #ff4d53);
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 5px 5px 5px;
  }

  .current-temp-dot {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;

    .fa {
      font-size: 20px;
    }

    .dot {
      width: 100%;
      margin-bottom: -7px;
    }
  }

  .count-slide-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white-color;
    margin-bottom: -5px;
    font-size: 14px;
  }

  .count-slide-wrapper span {
    text-align: center;
  }

  .energry-data-points {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 15px;

    .energy-info {
      width: 50%;
      margin: 15px 0;

      .energy-label {
        font-size: 14px;
        line-height: 21px;
        color: $immozy-dark;
      }

      .energy-value {
        font-size: 20px;
        line-height: 30px;
        font-weight: $font-bold;
        text-transform: capitalize;

        &.small {
          text-transform: lowercase;
        }
      }
    }
    .no-data {
      padding: 20px 15px;

      p {
        font-size: 16px;
      }
    }
  }
}

.data-privacy-links {
  a {
    margin: 0 15px 0 10px;
    cursor: pointer;
  }
}

.energy-title {
  font-size: 14px;
  color: $primary-color;
  font-weight: 600;
}
