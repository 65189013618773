@import "~assets/css/variable.scss";

.auth-header {
  width: 100%;
  height: 100px;
  padding: 0 15px;
  position: fixed;
  z-index: 1;

  .logo {
    width: 100%;
    max-width: 370px;
    height: 100%;
  }
}

.text-info {
  color: $secondary-color !important;
}

a,
a:hover {
  color: var(--app-primary-color);
}

/* new  */

.center-div {
  margin: 0 auto;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $white-color;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  .card-block {
    padding: 1.25rem;

    p {
      line-height: 1.4;
      color: $grey-light;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    input {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .reg-now-text {
      margin: 5px 0 20px 0;
      font-size: 16px;
    }
  }
}

// .input-login-style {
//   border-radius: 5px;
//   border: 1px solid transparent;
//   height: 54px;
//   padding: 5px 20px;
// }

.input-login-style {
  background-color: $bg-white !important;
  border: 1px solid $border-grey !important;
}

.checkbox-fade,
.checkbox-zoom {
  display: inline-block;
  margin-right: 15px;
}

.text-inverse {
  color: #2f2659;
}

.signup-link {
  color: $grey-light;
  text-decoration: underline;
  font-size: 12px;
  color: $grey-light;
  letter-spacing: 0.01em;
  line-height: 15px;
}

.checkbox-fade {
  &.fade-in-primary {
    .cr {
      border-radius: 0;
      border: 2px solid $secondary-color;
      cursor: pointer;
      display: inline-block;
      height: 20px;
      margin-right: 1rem;
      position: relative;
      width: 20px;
    }
  }

  label {
    line-height: 20px;

    input[type="checkbox"] {
      width: 15px;
      height: 12px;
      padding: 0px;
      margin-left: 0px;
      margin-right: 5px;
    }
  }
}

.error-msg-label {
  margin-top: 14px;
  margin-bottom: 15.4px;
  font-size: 12px;
  color: $primary-color;
  text-align: center;
  letter-spacing: 0.01em;
  font-weight: $font-bold;
  font-weight: $font-light !important;
}

.success-msg-label {
  font-size: 14px;
  color: #58b32d;
  text-align: center;
  line-height: 20px;
  margin-top: 10px;
  font-weight: $font-light !important;
}

.margin-t-30 {
  margin-top: 30px;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: $secondary-color;
  border-color: $secondary-color;
  box-shadow: none;
}

.form-material {
  .form-control {
    border-radius: 5px;
    border: 1px solid transparent;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
    height: 54px;
    padding: 5px 20px;
  }
}

.form-control {
  font-size: 14px !important;

  &:focus {
    box-shadow: none !important;
  }
}

.wc-txt {
  p {
    font-size: 13px !important;
    margin-top: 10px;
  }
}

#inputID {
  &::placeholder {
    color: #888888;
    opacity: 1;
  }
}

.pwd-div {
  width: 21%;
  height: 5px;
  margin-right: 10px;
}

.error-border {
  background-color: $bg-white !important;
  border: 2px solid $primary-color !important;
  box-shadow: none !important;
}

.profile-tab {
  input[type="password"],
  input[type="text"] {
    font-size: 15px !important;
    color: black !important;
    outline: none;
    background-color: rgb(246, 246, 246);
    border: 1px solid rgb(180, 184, 190);
    height: 38px !important;
  }

  .pwd-icon {
    top: 11px;
  }
}

.new-div {
  margin-bottom: 0px;
  float: right;
  width: 100%;
  margin-right: 4%;
}

.eye-con {
  position: absolute;
  top: 110px;
  right: 12px;
  font-size: 18px;
  cursor: pointer;
}

.password-input {
  border: none;
  outline: 0;
  font-size: 1em;
  height: 38px !important;
  min-width: 250px;
  background-size: 17px 17px;
  background-position: right top;
  font-size: 14px !important;
  color: #495057;
}

.password-input-wrapper {
  min-width: 250px;
  width: 100%;
  position: relative;
}

.pwd-label {
  font-size: 12px;
  float: right;
  margin-right: 10px;
}

.border-div {
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  height: 54px;
  padding: 5px 20px;
}

.forward-btn {
  height: 60px;
  width: 210px;
  background-color: $primary-color;
  border-radius: 30px;
  font-size: 13px;
  color: $white-color;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  box-shadow: none !important;
  transition: all 0.3s ease;

  &:hover {
    background-color: $hover-red;
    transition: background-color 0.5s ease 0s;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2) !important;
  }
}

.forgot-title {
  font-size: 16px;
  color: $grey-light;
  text-align: center;
  letter-spacing: 0.06em;
  font-weight: $font-bold;
  line-height: 45px;
  text-transform: uppercase;
}

.lang-section {
  position: absolute;
  right: 0;
  top: 65px;
}

.back-link {
  position: absolute;
  right: -20px;
  top: 7px;
  font-size: 11px;
}

.mobile-verify-msg {
  display: none;
}

.social-div {
  .forward-btn {
    position: relative;
  }
  .login-btn-loader {
    color: $white-color;
  }
}
@media only screen and (max-width: 992px) {
  .login-block {
    &.offline-404 {
      .auth-box {
        margin-top: 45px;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .login-block {
    .pwd-div {
      width: 20% !important;
    }

    .auth-box {
      padding: 10px 10px !important;
      width: 97% !important;
    }

    .title {
      margin: -10px -10px 0 !important;
      font-size: 20px !important;
    }

    .mobile-hide {
      display: none;
    }

    .mobile-verify-msg {
      display: unset;
    }

    .desktop-verify {
      display: none;
    }
  }

  .login-block {
    &.offline-404 {
      .auth-box {
        h1 {
          font-size: 60px;
        }
      }
    }
  }

  .login-block {
    &.offline-404 {
      .auth-box {
        h2 {
          font-size: 0.8rem;
        }
      }
    }
  }
}

.logo-imz {
  width: 78px;
  padding-top: 2%;
  margin-left: 4%;
}

.email-verify-container {
  max-width: 509px;
  margin: 0 auto;

  .email-logo {
    width: 205px;
  }

  .verify-title {
    font-weight: 450;
    font-size: 32px;
    line-height: 44px;
    font-weight: $font-bold;
  }

  .verify-subtitle {
    font-size: 14px;
    line-height: 21px;
  }

  .resend-title {
    font-size: 14px;
    line-height: 21px;
    color: $primary-color;
    font-weight: $font-bold;
  }

  .form-group {
    width: 90%;
    text-align: left;
    margin: 0 auto;
  }

  .input-label {
    font-size: 16px;
    line-height: 22px;
    font-weight: $font-bold;
  }

  .login-input-box {
    height: 50px;
    width: 410px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 5px 10px;
    outline: none;
  }

  .mail-send-btn {
    width: 238px;
    height: 64px;
    background: $white-color;
    border: 1px solid #caced2;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02);
    border-radius: 40px;
  }
}

.login-google {
  height: 46px;
  width: 46px;
  border-radius: 50% !important;
  padding: 0 !important;
  box-shadow: none !important;
  border: 1px solid grey !important;
  margin-right: 15px;

  & > div {
    margin-left: 3px !important;
    background: transparent !important;
  }
}

.kep-login-facebook {
  height: 46px;
  width: 46px;
  border-radius: 50% !important;
  padding: 0 !important;

  .fa {
    margin-right: 0px;
  }
}

.social-div {
  // float: right;
  // margin-right: 50px;
  display: none;
}

.fb {
  width: 50px;
  margin-right: 20px;
}

@media only screen and (max-width: 1280px) {
  .social-div {
    flex-wrap: wrap;

    .social-login-btn {
      width: 100%;
      margin-top: 10px;
    }

    .divider-signup {
      display: none;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .social-div {
    display: unset !important;
  }

  .social-login-btn {
    margin-top: 15px;
  }

  .divider-signup {
    display: none !important;
  }
}

.forgot-password-container {
  .email-verify-container {
    margin-top: 5% !important;
    margin: 0 auto;
    width: 50%;

    .verify-subtitle {
      font-size: 16px;
    }

    .form-group {
      margin: unset;
      width: unset;
    }

    .mail-send-btn {
      width: 410px;
      background-color: $primary-color;
      color: $white-color;
    }
  }

  .link-section {
    width: 100%;
    max-width: 410px;
    margin-top: 5%;
    text-decoration: underline;
  }
}

.reset-pwd-container {
  .form-group .pwd-icon,
  .pwd-label {
    display: none;
  }
}
