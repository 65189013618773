.text--primary {
  color: $primary-color;
}

span.disable-links {
  pointer-events: none;
}

.property-card-wrapper {
  &::-webkit-scrollbar {
    width: 10px;
    height: 18px;
    background-color: $white-color;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: $white-color;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: #cecece;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  margin-bottom: 15px;
  justify-content: center;
  transition: background 0.4s ease;

  .column-wrapper {
    width: 25%;
    max-height: 350px;
    position: relative;

    &.column-w-100 {
      width: 100% !important;
    }
  }
}

.main-section {
  min-height: calc(100vh - 100px);
  padding-top: 80px;
  max-width: 1920px;
  margin: 0 auto;
}

.section-pd-top {
  padding-top: 40px;
}

.dropdown-toggle,
.dropdown-toggle:hover {
  color: $white-color;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.dropdown-item:active,
.dropdown-item:focus {
  background-color: $secondary-color;
  color: $white-color !important;
}

.dropdown-item:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.dropdown-menu {
  padding: 0;
}

.dropdown-divider {
  margin: 0;
}

.btn {
  &:hover {
    color: $white-color !important;
    background-color: $primary-color;
  }

  &.btn-map {
    background-color: $white-color;
    background-repeat: no-repeat;
    background-position-x: 10%;
    background-position-y: 50%;
    width: 109px;
    box-shadow: $btn-box-shadow;
    display: flex;
    align-items: center;

    svg {
      fill: $secondary-color;
      width: 25px;
    }

    p {
      line-height: 1;
      // width: calc(100% - 25px);
    }

    &:hover {
      background-color: $white-color !important;
      box-shadow: $btn-box-shadow-hover;
      border-color: $border-grey;
      color: $secondary-color !important;
    }

    &.active {
      background-color: $primary-color;
      color: $white-color;

      svg {
        fill: $white-color;
      }

      &:hover {
        background-color: $hover-red !important;
        color: $white-color !important;
        box-shadow: 0 0 0 0 !important;
      }
    }

    .map-btn-icon {
      margin-left: -32px;
      margin-right: 10px;
    }
  }
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

textarea {
  &:focus {

    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
      color: transparent;
    }
  }
}

input {

  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: #999999;
  }
}

textarea {

  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: #999999;
  }
}

label {
  display: block;
  margin: 0;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.form-group {
  position: relative;
}

.form-group {
  .pwd-icon {
    position: absolute;
    top: 10px;
    right: 12px;
    font-size: 18px;
    cursor: pointer;
  }
}

.section-heading {
  padding: 5px 15px 15px;
  text-align: center;

  .title {
    position: relative;
    font-size: 30px;
    color: rgba(147, 40, 73, 1);
    text-align: left;
    letter-spacing: 0.04em;
    font-weight: $font-bold;
    min-width: 210px;
  }

  h4 {
    font-weight: $font-regular;
  }
}

.title-text-show {
  display: inline-flex;
}

.save-search {
  display: inline-block;
  height: 35px;
  width: 125px;
  line-height: 37px;
  margin-left: 10px;
  font-size: 11px;
  color: $grey-light;
  text-align: center;
  border-radius: 18px;
  transition: all 0.3s ease;

  svg {
    fill: $grey-light;
    width: 25px;
  }

  &:hover {
    background-color: #f4f5f6;
  }

  &.active {
    background-color: $white-color;
    color: $primary-color;

    svg {
      fill: $primary-color;
    }
  }
}

.curved-div {
  position: fixed;
  top: 80px;
  background: white;
  width: 100%;
  max-width: 1920px;
  z-index: 3;
  padding: 10px;
  border-bottom-left-radius: 33px;
  border-bottom-right-radius: 33px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 19px 0px;
  transition: all 0.2s ease-in-out;

  &.fixed {
    position: fixed;
    top: 30px;
    transition: all 0.6s ease;

    .section-heading {
      background-color: $white-color;
    }
  }

  &.favorites {
    position: fixed;
    top: 80px;
    padding: 5px;
  }

  .spinner-wrapper {
    position: absolute;
    right: -60px;
    top: -5px;
    display: contents;
  }
}

.search-result-section {
  background-color: $white-color;
  min-height: calc(100vh - 100px);
  position: relative;
}

.search-result-section .load-div {
  height: calc(100% - 61px);
  min-height: auto;
}

.header .row {
  margin: 0;
}

.filter-button {
  position: relative;
  min-height: 24px;
}

.filter-button button {
  width: 100px;
  border: none;
  position: absolute;
  top: -2px;
}

.filter-button button:before {
  content: "Filter";
  width: $full-width;
  height: $full-height;
  position: absolute;
  top: 0;
  letter-spacing: 0.5px;
  padding-left: 5px;
  padding-top: 1px;
}

.property-result-wrapper {
  width: $full-width;
  position: relative;
}

.search-result-section {
  .map-wrapper {
    position: absolute;
    width: 71.5%;
    right: 0;
    height: calc(100vh - 80px);
    max-width: 1370px;

    &.sticky {
      position: fixed;
      top: 80px;

      .custom-filters {
        top: 90px;
      }

      .full-screen-btn {
        top: 155px;
      }
    }

    &.sticky-full-map {
      height: calc(100vh - 220px) !important;
    }

    &.sticky-full-map-fav {
      height: calc(100vh - 170px) !important;
    }

    &.sticky.sticky-hide {
      overflow-y: hidden !important;
    }

    &.sticky-footer {
      position: absolute;
      height: calc(100vh - 80px);
      top: auto;
      bottom: 0;
    }
  }
}

.no-show {
  display: none;
}

.map-wrapper .map-content,
.map-wrapper .map-content .map-box {
  width: $full-width;
  height: $full-height;
}

.mapborder::after {
  content: "";
  position: absolute;
  width: $full-width;
  height: $full-height;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
  top: 0;
  cursor: not-allowed;
}

.full-screen-btn {
  position: absolute;
  z-index: 1;
  top: 80px;
  right: 5px;
  background-color: $white-color;
  padding: 5px 12px;
  border-radius: 50%;
  height: 49px;
  width: 49px;
  text-align: center;
  line-height: 34px;
  box-shadow: $btn-box-shadow-hover;
  border: 1px solid $border-grey;
  transition: all 0.3s ease;

  &:hover {
    border-color: $primary-color;

    svg {
      fill: $primary-color;
    }
  }

  svg {
    width: 22px;
    height: 22px;
    fill: $grey-light;
    transition: all 0.3s ease;
  }

  &.full-screen-btn-fixed {
    position: fixed;
    top: 230px !important;
  }
}

.map-wrapper {
  .custom-filters {
    position: absolute;
    z-index: 1;
    top: 15px;
    right: 0;
    height: 53px;
    transition: right 0.4s ease;
    border-bottom-left-radius: 27px;
    border-top-left-radius: 27px;
    transition: right 0.4s ease !important;
    width: 754px;

    &.hide-filters {
      right: -705px;
    }

    &.hide-filters-full {
      right: -704px;
    }

    &.custom-filters-fixed {
      position: fixed;
      top: 170px !important;
      transition: none;
    }

    .MuiGrid-container {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      height: 53px;
      border-bottom-left-radius: 27px;
      border-top-left-radius: 27px;
      align-content: center;
      padding-right: 10px;
    }

    .custom-checkbox {
      padding-left: 28px;
      min-height: auto !important;

      .custom-control-label {
        padding-top: 4px;
        font-size: 10px;

        &::before {
          left: -28px;
          top: 1px;
          height: 22px;
          width: 22px;
          border-radius: 6px !important;
          border: 1px solid #979797;
          box-shadow: none !important;
        }

        &::after {
          left: -28px;
          top: 1px;
          height: 22px;
          width: 22px;
          border-radius: 6px !important;
        }
      }
    }

    .map-icon {
      width: 20px;
      margin-right: 3px;
      fill: $grey-light;
      margin-top: -5px;
    }
  }
}

.search-result-section .pagination {
  justify-content: center;
  height: 79px;
  width: 100%;
  padding: 24px 20px 0px 20px;
}

.map-pagination {
  margin-bottom: 15px;
  margin: 0 auto 15px;
  max-width: 100%;
}

.prev-class-map a {
  margin-left: 4%;
}

.favourites-section .map-wrapper {
  height: 99.5%;
}

.button-wrapper {
  .more-filter {
    top: 45px;
    left: 220px;

    &::before {
      content: "";
      position: absolute;
      top: -15px;
      left: 370px;
      border-bottom: 20px solid $white-color;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      -webkit-filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
      z-index: 201;
    }
  }
}

.filter-modal,
.preference-modal,
.search-profile-modal,
.edit-pref {
  width: $full-width;
  top: 65px;
  right: 0;
  z-index: -1;
  height: auto;
  position: absolute;
  opacity: 1;
  transform: translateY(-100vh);

  &.fade-out {
    -webkit-animation-duration: 0.4s !important;
    animation-duration: 0.4s !important;
  }

  &.fade-in {
    -webkit-animation-duration: 0.4s !important;
    animation-duration: 0.4s !important;
  }

  &.open {
    z-index: 11;
    opacity: 1;
    transform: translateY(0);
  }

  &.close-model {
    z-index: -1;
    opacity: 0;
    transform: translateY(-100vh);
    transition-delay: 0.5s !important;
  }
}

.filter-modal.save-search-modal {
  left: 198px;

  &::before {
    content: "";
    position: absolute;
    top: -15px;
    margin-left: -35px;
    z-index: 201;
    border-bottom: 20px solid $white-color;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    -webkit-filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
  }

  &.germen-model {
    left: 225px;
  }

  .custom-control-label {
    font-size: 14px !important;
    color: $grey-light !important;
    line-height: 21px !important;
  }

  .filter-wrapper {
    width: 446px !important;
    height: 182px !important;
  }
}

.filter-modal.reset-modal {
  left: 365px;
  top: 45px;

  &.germen-model {
    &::before {
      left: 390px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: -15px;
    left: 365.5px;
    z-index: 201;
    border-bottom: 20px solid $white-color;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    -webkit-filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
  }
}

.filter-modal {
  .show-default-modal {
    position: relative;
    display: block;
  }
}

.modal-content-wrapper {
  width: 70%;
  background-color: $white-color;
  margin: 0 auto;
  padding: 20px 0;
  box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.2);
  border-radius: 34px;
  position: relative;

  .container {
    padding: 0 30px;
    width: $full-width;
    max-width: 100%;
  }

  .heading-text {
    padding: 0 0 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #cecece;
    font-weight: 500;
  }


  .match-find-location {
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.09);
    padding: 10px 5%;

    &:last-child {
      box-shadow: none !important;
    }

    ul {
      text-align: initial;
      padding-left: 10%;

      li {
        margin-bottom: 5px;
        display: flex;
      }
    }

    img {
      margin-top: -10px;
      margin-left: 15px;
    }

    h6 {
      padding-left: 4%;
    }

    .btn-edit i {
      font-size: 15px;
      margin-left: 5px;
      color: $primary-color;
    }
  }
}


.criteria-info-wrapper {
  width: 50%;
  position: relative;
  padding-left: 20px;
}

.criteria-info-wrapper .heading {
  margin: 50px 0;
}

.notification-info .form-group {
  width: 25px;
  height: 25px;
}

.notification-info .form-group input {
  margin-top: 0;
}

.criteria-info-wrapper .score-dropdown-wrapper .custom-dropdown-item {
  min-height: 46px;
}

.modal-content-wrapper .close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
}

.scores-weightage-wrapper .scores-list {
  width: 70%;
  margin: 0 auto;
}

.scores-weightage-wrapper .weightage-graph {
  width: 50%;
  height: 150px;
  padding-left: 10%;
  padding-top: 2%;
}

.scores-list .range-item .icon {
  width: 40px;
  height: 40px;
  border: 1px solid #cecece;
  border-radius: 50%;
}

.scores-list .range-item .form-group {
  width: calc(100% - 60px);
}

.scores-list .range-item .icon i {
  color: #cccccc;
  font-size: 22px;
  margin: 0;
}

.form-control-range {
  cursor: pointer;
}

.filter-modal.show-default-modal,
.filter-modal.open,
.filter-modal.open .filter-wrapper,
.filter-modal.show-default-modal .filter-wrapper {
  transform: translateY(0);
}

.filter-wrapper {
  width: 90%;
  max-width: 992px;
  background-color: $white-color;
  margin: 0 auto;
  width: 584px;
  box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  right: 5px;
  border-radius: 20px;
  overflow: hidden;

  .checkbox-wrapper {
    .form-check {
      padding: 0;
      display: flex;
    }

    .form-check-input {
      position: relative;
      cursor: pointer;
      margin: 0;
    }

    .form-check-label {
      cursor: pointer;
      font-weight: 500;
      color: $black-color;
      margin-left: 10px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .year-input {
      width: 60px;
      border: none;
      margin: 0px 10px;

      .form-control:focus {
        border: none !important;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }

    input[type="number"] {
      -moz-appearance: textfield;
      text-align: center !important;
    }
  }

  .button-wrapper {
    button {
      min-width: 150px;
    }
  }

  .container {
    min-height: inherit;
    position: relative;
    width: $full-width;
    padding: 20px 15px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 30;
  }
}

.load-div {
  min-height: inherit;
  position: absolute;
  width: $full-width;
}

.load-div .text-info {
  width: 70px;
  height: 70px;
  font-size: 30px;
}

.fade-in {
  -webkit-animation: fadeIn ease 1s;
  animation: fadeIn ease 1s;
}

.fade-out {
  -webkit-animation: fadeOut ease 1s;
  animation: fadeOut ease 1s;
}

.fade-in-out {
  -webkit-animation: fadeInOut ease 0.8s;
  animation: fadeInOut ease 1s;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeInOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loading-dots .dot {
  display: inline;
  margin-left: 0.2em;
  margin-right: 0.2em;
  position: relative;
  opacity: 0;
  -webkit-animation: showHideDot 1.5s ease-in-out infinite;
  animation: showHideDot 1.5s ease-in-out infinite;
}

.loading-dots .dot.one {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.loading-dots .dot.two {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loading-dots .dot.three {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes showHideDot {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes showHideDot {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.DefaultBackground {
  background: $white-color !important;
  border-top: 5px solid #d8d8d8 !important;
  border-bottom: none !important;
  border-right: none !important;
  border-left: none !important;
}

.DefaultProgressBar_background__horizontal {
  top: -2px !important;
  height: 5px !important;
}

.DefaultProgressBar_progressBar {
  background-color: $primary-color !important;
}

.top-match {
  .handleContainer_1 .DefaultHandle_handle:nth-child(2) {
    pointer-events: none !important;
    border: 1px solid #d8d8d8 !important;

    &::before {
      background-image: url("../../assets/images/slider-handle-disable.png") !important;
      background-repeat: no-repeat;
    }
  }
}

.disabled-bar .DefaultHandle_handle {
  border: 1.5px solid #cecece !important;

  &:before {
    background-image: url("../../assets/images/slider-handle-disable.png") !important;
  }
}

.disabled-bar .DefaultProgressBar_progressBar {
  background-color: #cecece !important;
}

.profile-head {
  h5 {
    color: #333;
  }

  h6 {
    color: #4b246b;
  }

  .nav-tabs {
    .nav-link.active {
      border: none;
      border-bottom: 2px solid #4b246b;
    }
  }

  .nav-tabs .nav-link {
    font-weight: $font-bold;
    border: none;
  }
}

.add-prop-btn {
  font-weight: $font-bold;
  font-size: 30px;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  background-color: $primary-color;
  color: $white-color;

  &:hover {
    background-color: $hover-red;
  }
}

.new-prop {
  .nav-tabs .nav-link {
    font-size: 18px;
  }
}

.profile-tab {
  p {
    font-size: 16px;
  }

  label {
    font-weight: $font-bold;
  }

  input :focus {
    border: 1px solid lightgray;
  }

  input {
    border: 1px solid lightgray;
    font-size: 14px;
    box-shadow: none !important;
    border-radius: 5px;
    height: 54px;
    padding: 5px 20px !important;
    outline: none;
    color: #495057;
  }

  .pwd-icon {
    top: 10px;
  }
}

.summary-title {
  font-size: 18px !important;
  margin: 5px;
  font-weight: $font-bold;
  color: $primary-color;
}

.summary label {
  font-size: 16px;

  span {
    color: $secondary-color;
    margin-right: -10px;
    font-weight: $font-bold;
  }
}

.tab-content {
  font-size: 16px;
}

.p-icon {
  position: absolute;
  top: 10px;
  right: 12px;
  font-size: 18px;
  cursor: pointer;
}

.err-text {
  font-size: 13px;
  color: red;
  margin-bottom: 10px;
  margin-top: -10px;
}

.toast-body {
  background-color: $white-color;
}

.abc {
  margin-bottom: 10px;
  width: $full-width;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 2px;
  border-radius: 5px;
  height: 54px;
  padding: 5px 20px !important;
}

.name-input-profile {
  width: 49%;
  overflow: hidden;
}

.remove-photo {
  position: absolute;
  top: 0px;
  left: 89px;
  width: 12px;

  svg {
    fill: $secondary-color;
    background: white;
    padding: 1px;
    border-radius: 50%;

    &:hover {
      fill: $primary-color;
    }
  }
}

.backward,
.forward,
.onboarding2 {
  border: none;
  color: $white-color;
  text-decoration: none;
  transition: background 0.5s ease;
  -moz-transition: background 0.5s ease;
  -webkit-transition: background 0.5s ease;
  -o-transition: background 0.5s ease;
  display: inline-block;
  border-radius: 2px;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  font-weight: $font-bold;
  outline: none;
  background: $secondary-color;
  position: relative;
}

.forward,
.onboarding2 {
  padding: 7px 30px 7px 20px;
}

.btn-success {
  color: $white-color;
  background-color: $secondary-color !important;
  border-color: $secondary-color !important;
}

.error {
  color: red;
  margin-bottom: 0 !important;
  font-size: 12px;
}

.favorites-animation {
  position: fixed;
  left: var(--element-x-position);
  top: var(--element-y-position);
  z-index: 11;
  -webkit-animation: favoritesAni 0.8s ease-in;
  animation: favoritesAni 0.8s ease-in;

  i {
    color: $primary-color;
    font-size: 22px;
  }
}

@-webkit-keyframes favoritesAni {
  0% {
    left: var(--element-x-position);
    top: var(--element-y-position);
    transform: scale(1);
    z-index: 1111;
  }

  100% {
    left: calc(100% - 170px);
    top: 30px;
    transform: scale(0.3);
    z-index: 1111;
  }
}

@keyframes favoritesAni {
  0% {
    left: var(--element-x-position);
    top: var(--element-y-position);
    transform: scale(1);
    z-index: 1111;
  }

  100% {
    left: calc(100% - 170px);
    top: 30px;
    transform: scale(0.3);
    z-index: 1111;
  }
}

.DefaultHandle_handle {
  width: 30px;
  height: 30px;
  border: 1.5px solid #b5bec5;
  background-color: #fcfcfc;
  border-radius: 100%;
  outline: none;
  z-index: 2;
  cursor: pointer;
  box-shadow: $btn-box-shadow;

  &::after {
    display: none;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    background-image: url("../../assets/images/slider-handle.png") !important;
    background-repeat: no-repeat !important;
    background-position: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &:hover,
  &:focus {
    box-shadow: $btn-box-shadow-hover;

    &::before {
      background-image: url("../../assets/images/slider-handle-hover.png") !important;
    }
  }
}

.DefaultHandle_handle__horizontal {
  margin-left: -12px;
  top: -12px;
}

.DefaultHandle_handle__vertical {
  margin-top: -12px;
  left: -10px;
}

.DefaultHandle_handle__disabled {
  border-color: #dbdbdb;
}

.DefaultBackground {
  background-color: #fcfcfc;
  height: 15px;
  width: 100%;
  border: 1px solid #d8d8d8;
  position: relative;
}

.DefaultBackground_background__horizontal {
  height: 15px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%;
}

.DefaultBackground_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%;
}

.rheostat {
  position: relative;
  overflow: visible;
}

.medium-modal {
  .title {
    font-size: 16px;
    font-weight: $font-bold;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-align: center;
    color: $grey-light;

    letter-spacing: 0.06em;
    line-height: 18px;
  }

  .desc-dark {
    font-size: 14px;
    color: $secondary-color;
    line-height: 21px;
  }

  .desc-light {
    font-size: 14px;
    color: $grey-light;
    line-height: 21px;
  }
}

.verify-email {
  color: $grey-light;
  padding: 25px 35px;
  width: 584px;
  background-color: $white-color;
  border-radius: 20px;
  box-shadow: 0px 8px 16px 8px rgba(0, 0, 0, 0.14);
  text-align: center;
  margin: 0 auto;

  .title {
    margin-bottom: 15px;
    text-align: center;
  }

  p {
    text-align: left;

    &.list-item {
      font-weight: 600;
      position: relative;
      padding-left: 15px;

      &::before {
        position: absolute;
        content: "-";
        left: 0;
        font-weight: 600;
      }
    }
  }

  .button {
    height: 35px;
    width: 140px;
    margin: 0 auto;
    background-color: $primary-color;
    border-radius: 18px;
    color: $white-color;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.4s ease;

    &:hover {
      background-color: $hover-red;
    }
  }
}

.login-block {
  min-height: 100vh;
  padding: 20px 0;
  background: url("../images/Hamburg-city.jpeg") no-repeat center;
  background-size: cover;

  .header-onboarding {
    margin-bottom: 30px;

    .why-link {
      font-size: 25px;
      color: $white-color;
      text-align: center;
      max-width: 300px;
      margin: 15px auto 0;
      line-height: 35px;
      font-weight: 600;
    }

    .question-text {
      font-size: 40px;
      color: $white-color;
      text-align: center;
      letter-spacing: 0.01em;
      line-height: 39px;
      font-weight: $font-bold;
      margin: 0 auto;
    }
  }

  .auth-box {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    border-radius: 12px;
    max-width: 550px;
    padding: 10px 50px;
    border: none !important;

    .link-name {
      position: absolute;
      width: 25px;
      left: 0;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    background-color: $primary-color;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    padding: 23px 10px;
    color: $white-color;
    text-align: center;
    margin: -10px -50px 0;
  }

  .name-input {
    .form-group {
      max-width: 189px;

      &:first-child {
        padding-right: 10px;
      }
    }
  }

  .form-group {
    label {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .input-login-style {
      height: 38px;
      background-color: $bg-white;
      border: 1px solid $border-grey;
      border-radius: 5px;
    }
  }

  .button {
    height: 35px;
    width: 121px;
    margin: 0 auto;
    background-color: $primary-color;
    border-radius: 18px;
    color: $white-color;
    font-size: 12px;
    font-weight: 600;
  }

  .custom-checkbox {
    user-select: none;
    padding-left: 0;

    &.checked {
      label {
        &::after {
          position: absolute;
          content: "\f00c";
          font-family: "FontAwesome";
          top: 3px;
          left: 0;
          width: 25px;
          height: 25px;
          text-align: center;
          font-size: 16px;
          line-height: 25px;
          color: $primary-color;
        }
      }
    }

    label {
      position: relative;
      padding-left: 37px;

      &::before {
        position: absolute;
        content: "";
        top: 3px;
        left: 0;
        width: 25px;
        height: 25px;
        background-color: $white-color;
        border: 1px solid $grey-light;
        border-radius: 8px;
      }
    }
  }

  .spin-img {
    margin-top: 0px;
  }
}

.advance-option {
  height: 0;
  overflow: hidden;
  transition: all 0.4s ease;

  &.show {
    height: 290px;
  }
}

.modal-body-text {
  font-size: 16px;
  color: $grey-light;
  text-align: center;
  line-height: 21px;
}

.modal-btn {
  height: 45px;
  min-width: 162px;
  background-color: $primary-color;
  border-radius: 30px;
  font-size: 16px;
  font-weight: $font-bold;
  color: $white-color;
  text-align: center;
  letter-spacing: 0.06em;
  // text-transform: uppercase;
  box-shadow: none !important;
  transition: all 0.3s ease;

  &:hover {
    transition: background-color 0.5s ease 0s;
    background-color: $hover-red;
  }
}

.back-btn {
  .image-gallery-icon .image-gallery-svg {
    border: 1px solid #cecece;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0) !important;
    }
  }

  .image-gallery-icon {
    outline: none !important;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    transition: opacity 0.5s ease;
    padding: 0 20px;
    margin-top: 5px;

    .image-gallery-svg {
      height: 45px;
      color: $black-color;
      width: 45px;
      background-color: $white-color;
      border-radius: 50%;
      outline: none;

      &:active {
        color: $primary-color !important;
      }
    }
  }
}

.owner-info {
  margin: 80px auto;
  background-color: rgba(160, 172, 206, 0.25);
  border-radius: 59px;
  max-width: 1200px;

  .owner-detail {
    max-width: 310px;
    width: 100%;
    border-radius: 59px;
    background-color: $immozy-lightblue;

    .owner-img {
      height: 118px;
      width: 118px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }

    .owner-detail-content {
      font-size: 14px;
      color: $white-color;
      width: calc(100% - 158px);
      margin-left: 10px;

      h2 {
        font-size: 16px;
        font-weight: 600;
      }

      p {
        line-height: 13px;
      }

      a {
        color: $white-color;
        transition: all 0.3s ease;

        &:hover {
          color: $primary-color;
          text-decoration: underline;
        }
      }
    }
  }

  .owner-description {
    width: calc(100% - 310px);
    padding: 5px 30px 5px 15px;
    font-weight: 600;
    place-content: space-between;

    .ow-details {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 17px;
      color: rgba(0, 0, 0, 1);
      line-height: 20px;
      font-weight: 500;
    }

    .button {
      max-width: 207px;
      width: 100%;
      margin: 0 0 0 15px;
      padding: 0 30px;
      background-color: $primary-color;
      border-radius: 60px;
      color: $white-color;
      font-size: 15px;
      height: 60px;
      line-height: 60px;
      transition: all 0.4s ease;
      font-weight: 600;
      text-transform: capitalize;

      &:hover {
        color: $white-color !important;
        background-color: $hover-red !important;

        a {
          color: $white-color !important;
        }
      }

      &:active {
        background-color: #c41c50 !important;
      }
    }
  }
}

.more-filter-icon {
  width: 20px !important;
}

.new-style-btn {
  font-size: 13px !important;
  border-radius: 24px !important;
  background: $white-color !important;
  border: 1px solid #CACED2 !important;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02) !important;
  border-radius: 40px;
  color: #000 !important;
  font-weight: $font-light;
}
