.landing-page-wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;

  .property-card {
    .swiper-wrapper-div {
      .swiper-slide {
        min-width: 341px;
        // width: 100% !important;
      }
    }
  }
}

.realtor-page-container{
  margin:20px;
  padding:10px;
  color:#fff;  
}

.realtor-type{
    margin: 0 auto;
    text-align: center;
  }

.logo-img-realtor{
  position:absolute;

  img{    
    width: 100px;
    margin-top: -27px;
    margin-left: 25px;
  }
}

@media only screen and (max-width: 1050px) {
  .landing-page-wrapper {
    &.property-card-wrapper {
      padding: 0px 10px 0 0 !important;
    }

    .column-wrapper {
      width: 50% !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .landing-page-wrapper {
    .column-wrapper {
      width: 50% !important;
    }
  }
}

@media only screen and (max-width: 500px) {
  .landing-page-wrapper {
    .column-wrapper {
      width: 100% !important;
    }

    .property-card {
      .swiper-wrapper-div {
        .swiper-slide {
          min-width: unset !important;
        }
      }
    }
  }
}

@-moz-document url-prefix() {
  .landing-page-wrapper {
    overflow: hidden;
  }
}