@media only screen and (min-width: 2560px) {
  .loading-background {
    .loading-bar1 {
      top: 300px;
    }

    .loader-text-1 {
      top: 500px;
    }

    .loader-text-2 {
      top: 560px;
    }

    .loader-text-3 {
      top: 700px;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .search-result-section {
    .map-wrapper {
      overflow: hidden;
      transition: all 0.3s ease;

      &.sticky {
        right: var(--element-window-width);
        transition: all 0.3s ease;
      }

      &.sticky-footer {
        right: 0;
      }

      .custom-filters {
        &.hide-filters {
          right: -705px;
        }

        &.custom-filters-fixed {
          right: var(--element-window-width);

          &.hide-filters {
            right: calc(-700px + var(--element-window-width)) !important;

            .e {
              box-shadow: none;
            }

            .map-filter {
              z-index: -1;
            }
          }
        }
      }
    }
  }

  .full-screen-btn-fixed {
    &.full-screen-btn-fixed {
      right: calc(var(--element-window-width) + 7px);
    }
  }
}

@media only screen and (min-width: 1680px) {
  .my-search-modal {
    top: 200px !important;
  }

  .image-gallery-popup {
    &.one-image-slider {
      .image-gallery-image {
        height: calc(100vh - 235px) !important;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .pagination-width {
    max-width: unset;
  }

  .property-result-wrapper {
    max-width: auto;
  }

  .search-result-section .map-wrapper {
    width: 71%;

    .custom-filters {
      &.hide-filters {
        right: -670px;
      }
    }

    .map-filter {
      font-size: 11px;
    }
  }

  .map-wrapper .custom-filters {
    width: 725px;
  }

  .welcome-screen {
    background-size: 110%;
  }
}

@media only screen and (max-width: 1600px) {
  .more-filter-height {
    height: 470px !important;
  }
}

@media only screen and (max-width: 1440px) {
  .social-div {
    display: unset !important;

    .forward-btn{
        margin: 0 auto !important;
    }
  }

  .social-login-btn {
    margin-top: 15px;
  }

  .divider-signup {
    display: none !important;
  }

  .immozy-center-wrapper
    .module-card-wrapper
    .module-card.slider-module
    .property-card-wrapper
    .column-wrapper {
    width: 33.33% !important;
  }

  .property-slider {
    .slick-list {
      width: 90% !important;
      margin: 0 auto;
    }
  }

  .property-card-wrapper {
    .column-wrapper {
      width: 33.33%;
    }
  }

  .welcome-screen {
    background-size: 125%;
  }

  .modal-content-wrapper {
    width: 65%;
  }

  .pagination {
    padding-left: 10px;
    padding-right: 5px;

    li {
      margin-right: 6px !important;
    }
  }

  .top-matches-container {
    top: 50px !important;
    height: 550px;
  }

  .small-screen-top {
    height: 90%;
    overflow-y: scroll;
    padding: 0 !important;
    margin-top: 25px;
  }

  .buying-process-container {
    .buying-steps-progress {
      .progress-nav-arrow {
        width: 215px;
      }
      .arrow-container {
        .arrow-label {
          width: 215px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1399px) {
  .buying-process-container {
    .buying-steps-progress {
      .progress-nav-arrow {
        width: 180px;
      }
      .box-link-container {
        width: 150px;
        .box-link-label {
          font-size: 15px;
        }

        .move-sekeleton {
          .react-loading-skeleton {
            width: 130px !important;
          }
        }
      }
      .arrow-container {
        &.active {
          @keyframes movearrow {
            0% {
              left: 40px;
            }
            100% {
              left: 60px;
            }
          }
        }
        .arrow-label {
          width: 180px;
          font-size: 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .email-verify-container .email-logo {
    width: 100px;
  }

  .section-heading {
    padding: 10px 0px 15px !important;

    .title {
      font-size: 24px !important;
    }
  }

  .preference-modal {
    top: 50px !important;
  }

  .search-profile-modal {
    top: -60px !important;

    .modal-content-wrapper .match-find-location h6 {
      padding-left: 4% !important;
    }
  }

  .pagination {
    li {
      margin-right: 3px !important;
    }

    a {
      font-size: 16px !important;
    }
  }

  .more-filter-height {
    height: 400px !important;

    .filter-data-container {
      height: 82% !important;
    }
  }

  .button-item {
    position: relative;
  }

  .sort-drop {
    font-size: 12px;
    margin-top: 9px;
  }

  .top-matches-container {
    height: 420px !important;
  }

  .small-screen-top {
    height: 90%;
    overflow-y: scroll;
    padding: 0 !important;
    margin-top: 25px;
  }

  .map-wishlist {
    margin-right: 20px;
  }

  .map-wishlist {
    margin-right: 15px;
  }

  .internet-speed {
    margin-bottom: 20px !important;
  }

  .filter-modal.save-search-modal {
    left: 165px !important;

    &.germen-model {
      left: 185px !important;
    }
  }

  .welcome-screen {
    .section-title {
      font-size: 35px;
    }

    .section-description {
      font-size: 15px;
    }

    .section-sub-title {
      font-size: 24px;
    }

    .title {
      font-size: 35px;
    }

    .logo {
      width: 90px;
    }
  }

  .quest-main {
    .custom-control-label {
      font-size: 16px !important;
      color: $grey-light;
      font-weight: $font-bold;
    }

    .question-text {
      font-size: 30px;
    }

    .skip-btn {
      width: 120px !important;
    }

    .tips-popup {
      max-height: 590px;

      .card-img {
        height: 145px !important;
        margin-bottom: 20px !important;
      }

      .tips-banner {
        height: 200px;
        background-position: 100% 65%;
      }

      .tips-description {
        height: 350px;
      }
    }
  }

  .object-detail {
    .contact-popup {
      overflow-y: scroll;
      max-height: 80vh;
    }
  }

  .modal-content-wrapper {
    width: 70%;

    .container {
      padding: 0 15px;
    }
  }

  .scores-weightage-wrapper {
    .weightage-graph {
      width: calc(100% - 250px);
    }
  }

  .add-prop-main {
    .modal-btn {
      width: 148px !important;
      min-width: 120px !important;
      font-size: 11px !important;
    }
  }

  .loading-background {
    .loading-bar1 {
      top: 150px;
    }

    .loader-text-1 {
      top: 280px;
    }

    .loader-text-2 {
      top: 330px;
    }

    .loader-text-3 {
      top: 430px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .social-div {
    flex-wrap: wrap;

    .social-login-btn {
      width: 100%;
      margin-top: 10px;
    }

    .divider-signup {
      display: none;
    }
  }

  .property-card {
    flex-direction: column;
    align-items: center;

    // width: 390px !important;
    .carousel-wrapper {
      margin-bottom: 20px;
    }
  }

  .curved-div {
    .button-wrapper {
      padding: 0 25px;
    }
  }

  .reset-modal {
    left: 320px !important;

    &.germen-model {
      &::before {
        left: 420px !important;
      }
    }
  }

  .welcome-screen {
    background-size: 135%;
    background-position: 40% 100%;

    .section-title {
      font-size: 45px;
    }

    .section-description {
      font-size: 17px;
    }

    .title {
      font-size: 35px;
    }

    .section-sub-title {
      font-size: 25px;
    }

    .button {
      height: 50px;
      line-height: 50px;
    }
  }

  .filter-modal.save-search-modal {
    left: 158px !important;
    top: 62px;
  }

  .banner-div {
    .banner-title,
    p {
      left: 12%;
    }
  }
}

@media only screen and (max-width: 1210px) {
  .logo-img {
    margin-left: 20px;
    margin-right: 20px;
  }

  .object-detail {
    .model-wrapper {
      .image-gallery {
        .image-gallery-content {
          .image-gallery-slide-wrapper {
            .image-gallery-swipe {
              width: calc(100% - 210px);
            }
          }
        }

        .image-gallery-left-nav,
        .image-gallery-right-nav {
          padding: 0;
          margin: 50px 30px;
        }
      }
    }
  }

  .curved-div {
    .button-wrapper {
      .button-filter,
      .button-apply {
        width: auto !important;
        font-size: 11px;
        padding: 0 15px;
      }
    }
  }

  .reset-modal {
    left: 320px !important;

    &.germen-model {
      &::before {
        left: 420px !important;
      }
    }
  }

  .calculator .calculator-form .bank-group img {
    padding: 0 5px;
  }
}

@media only screen and (max-width: 1050px) {
  .logo-imz {
    width: 50px;
  }

  .email-verify-container {
    padding: 2%;

    .verify-subtitle,
    .resend-title {
      margin: 10% 0 !important;
    }

    .email-logo {
      width: 80px;
    }
  }

  .sort-div,
  .sort-div-wrapper {
    width: 145px !important;
  }

  .sort-container {
    width: 105px !important;
  }

  .reset-filter {
    font-size: 10px !important;
  }

  .object-detail {
    .object-image-gallery {
      .img-wrapper {
        height: 200px;

        &.lg {
          height: 400px;
        }
      }
    }
  }

  .search-result-section {
    .map-wrapper {
      width: 67%;
    }
  }

  .property-card-wrapper {
    padding: 0 10px 0px 0 !important;
  }

  .map-wrapper {
    .custom-filters {
      width: 665px !important;

      &.hide-filters {
        right: -605px !important;

        .map-filter {
          margin-left: 20px;
        }
      }

      .map-filter {
        padding-right: 0;

        .ml-2 {
          margin-left: 0 !important;
        }
      }

      .map-icon {
        width: 19px;

        &.park-icon {
          width: 15px;
        }

        &.transport-icon {
          width: 12px;
        }

        &.supermarket-icon {
          width: 16px;
        }

        &.medical-icon {
          width: 17px;
        }
      }

      img {
        &.pointer {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .banner-div {
    .banner-title,
    p {
      left: 8%;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .property-card-wrapper {
    .column-wrapper {
      width: 50%;
    }
  }
}

@media only screen and (max-width: 992px) {
  .scores-weightage-wrapper {
    flex-direction: column;
  }

  .scores-weightage-wrapper .weightage-graph,
  .scores-weightage-wrapper .scores-list {
    width: $full-width;
  }

  .scores-weightage-wrapper .scores-list {
    margin-right: 0 !important;
    margin-bottom: 15px;
  }

  .criteria-info-wrapper {
    width: 60%;
    justify-content: center !important;
  }

  .welcome-screen {
    .section-title {
      font-size: 40px;
    }

    .section-description {
      font-size: 16px;
    }

    .title {
      font-size: 30px;
    }

    .section-sub-title {
      font-size: 22px;
    }

    .button {
      height: 45px;
      line-height: 45px;
    }
  }

  .curved-div {
    .button-wrapper {
      padding: 0;

      .button-filter,
      .button-apply {
        width: auto !important;
        font-size: 11px;
        padding: 0 15px;
      }
    }

    .reset-filter-img {
      margin: 0 !important;
    }
  }
}

@media only screen and (max-width: 840px) {
  .property-card-wrapper {
    .column-wrapper {
      width: 100%;

      .property-card {
        max-width: 440px;
        margin: 10px auto;
      }
    }
  }

  .object-detail {
    .object-image-gallery {
      .img-wrapper {
        height: 175px;

        &.lg {
          height: 350px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .welcome-screen .container {
    max-width: 85%;
  }

  .header .logo h1 {
    font-size: 28px;
    padding: 8px 0;
  }

  .object-detail {
    .object-image-gallery {
      .img-wrapper {
        height: 150px;

        &.lg {
          height: 300px;
        }
      }
    }
  }
}

//height related query
@media only screen and (min-height: 1080px) {
  .welcome-screen {
    background-position: 50% 130%;

    .section-title {
      margin-top: 40px;
      font-size: 50px;
    }

    .section-description {
      margin-bottom: 25px;
      font-size: 20px;
    }

    .title {
      margin-top: 30px;
      font-size: 40px;
    }

    .section-sub-title {
      font-size: 22px;
      font-size: 30px;
    }

    .button {
      margin-top: 70px;
      height: 65px;
      line-height: 65px;
    }
  }
}

@media only screen and (max-height: 630px) {
  .model-wrapper {
    .question-popup {
      top: 150px;
    }
  }
}

@media only screen and (max-height: 768px) {
  .contact-pop-up-wrapper {
    height: 90vh;

    .left-pannel {
      padding: 20px 50px;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      .realtor-name {
        margin-bottom: 15px !important;
      }

      .offer-container {
        .offer-title {
          font-size: 26px;
          margin-bottom: 5px;
        }

        .completion-steps-checkmark-wrapper {
          margin-bottom: 160px;
        }

        .react-tel-input {
          input {
            height: 40px !important;
          }
        }

        .personal-info-form-wrapper .form-group {
          margin-bottom: 15px;

          .form-control {
            padding: 7px 12px;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 18px;
        background-color: $white-color;
        border-radius: 7px;
      }

      &::-webkit-scrollbar-thumb {
        height: 6px;
        background-clip: padding-box;
        border-radius: 10px;
        background-color: $white-color;
      }

      &::-webkit-scrollbar-thumb {
        height: 6px;
        background-clip: padding-box;
        border-radius: 10px;
        background-color: #dedede;
      }

      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
    }

    .offer-container .contact-seller-instruction-label {
      margin-bottom: 40px !important;
    }
  }

  .calculator .calculator-form .bank-group {
    margin-top: 10%;
  }

  .finance-onb {
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 0px;

    &.welcome-container .question-container .action-section.show-up {
      margin-top: -4% !important;
      width: 80%;
    }

    .question-text {
      font-size: 26px !important;
      height: 60px !important;
    }

    .finance-options-container {
      min-height: 235px;
      margin-top: 0px;
    }

    .thank-you-wrapper .chat-icon {
      margin: 25px 0;
    }

    .show-margin {
      margin-bottom: 0;
    }
  }

  .image-gallery-popup {
    &.one-image-slider {
      .image-gallery-image {
        height: calc(100vh - 235px) !important;
      }
    }
  }

  .calendly-inline-widget {
    height: 575px !important;
  }

  .more-filter-height {
    height: 400px !important;
  }

  .small-screen-top {
    height: 90%;
    overflow-y: scroll;
    padding: 0 !important;
    margin-top: 25px;
  }

  .personal-message {
    margin-bottom: 10%;
  }

  .contact-warning {
    margin-bottom: 15px;
    margin-top: -10px;
  }
}

@media only screen and (max-height: 900px) {
  .success-seller-cotainer {
    min-height: 400px !important;
  }

  .model-wrapper {
    overflow: hidden;
  }

  .top-matches-container {
    height: 550px !important;
  }

  .small-screen-top {
    height: 90%;
    overflow-y: scroll;
    padding: 0 !important;
    margin-top: 25px;
  }

  .contact-form.seller-form {
    overflow: hidden;

    &::-webkit-scrollbar {
      width: 7px;
      height: 18px;
      background-color: $white-color;
      border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb {
      height: 6px;
      background-clip: padding-box;
      border-radius: 10px;
      background-color: $white-color;
    }

    &::-webkit-scrollbar-thumb {
      height: 6px;
      background-clip: padding-box;
      border-radius: 10px;
      background-color: $primary-color;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  .contact-form {
    .logo-header {
      margin-top: 0;
    }

    .contact-header {
      height: 70px;

      .contact-title {
        font-size: 20px;
      }
    }
  }

  .object-detail .model-wrapper,
  .model-wrapper {
    padding-top: 40px;
  }

  .offer-icons {
    height: 50px;
  }
}

@media only screen and (max-height: 740px) {
  .contact-form-pop-up-wrapper {
    overflow-x: hidden;
    overflow-y: auto;

    .first-part .submit-btn {
      padding: 10px;
      font-size: 14px;
    }

    .second-part .slider-wrapper .slide .big-text {
      font-size: 20px;
    }

    .second-part .slider-wrapper .slide .small-text {
      font-size: 14px;
    }

    .second-part .slider-wrapper .slide img {
      margin-bottom: -30px;
      height: 200px;
    }

    .contact-form-pop-up-wrapper .slick-dots {
      bottom: -5px !important;
    }
  }

  .contact-form.seller-form {
    overflow-x: hidden !important;
    overflow-y: scroll !important;

    .contact-form-container {
      max-height: 475px !important;
    }
  }

  .platform-contact-popup .right-pannel {
    .radio-container {
      margin-bottom: 0 !important;
    }

    .css-1hoke01-Control {
      padding: 1px 4px !important;
    }

    .contact-form-heading {
      margin-bottom: 51px;
    }

    .contact-form-card {
      padding: 5px 35px;

      .form-control {
        height: 40px;
      }

      .image-awatar-wrapper {
        height: 75px;
        margin: -45px 0 9px 0;

        img {
          height: inherit;
          width: 75px;
        }
      }
    }
  }

  .new-interested-popup {
    .first-part {
      .btn-new-int {
        margin-top: 9px !important;
      }

      .secure-label {
        margin-top: -12px !important;
      }

      .checkbox-fade,
      .first-part .checkbox-zoom {
        margin-top: -10px !important;
      }

      .long-desc-text {
        font-size: 8px;
        line-height: 11px;
      }
    }
  }

  .landing-page-signup {
    .first-part .logo-text-popup {
      margin-bottom: 10px;
    }

    .first-part .submit-btn-popup {
      margin-top: 40px;
    }
  }

  .first-part {
    padding-top: 20px;
    height: 500px;

    .text-inverse-popup {
      font-size: 12px;
    }

    // .custom-checkbox.checked label::after {
    //   top: calc(50% - 12px) !important;
    // }

    // .forward-btn {
    //   height: 50px !important;
    //   width: 190px !important;
    //   font-size: 20px !important;
    // }

    .bottom-text-popup {
      margin-bottom: 15px !important;
    }

    .login-form-popup .login-input-box {
      height: 35px;
    }

    .eye-icon-popup {
      top: 30px !important;
      font-size: 17px;
    }

    .logo-text-popup {
      margin-bottom: 25px;
    }

    .submit-btn-popup {
      margin: 15px 0;
    }

    .error-msg-label {
      // font-size: 14px;
      margin-top: -10px;
    }
  }

  .second-part {
    .first-para {
      .big-text {
        font-size: 25px;
        line-height: 30px;
      }

      // .small-text {
      //   font-size: 14px;
      // }
    }

    .red-bottom {
      height: 34px;

      p {
        font-size: 13px;
      }
    }
  }

  .red-bottom {
    height: 35px;

    p {
      font-size: 14px;
    }
  }

  .Welcome-popup-container {
    .welcome-popuup-bg {
      height: 588px;
      background-position-y: -30px;
    }

    .welcome-imozy {
      font-size: 25px;
      line-height: 20px;
    }

    .text-welcome-div {
      top: 340px;

      .welcome-details {
        font-size: 15px;
        padding: 3px 3%;
        line-height: 20px;
      }

      .welcome-btn-section {
        flex-direction: row;
        margin-top: 10px;
      }

      .welcome-btn {
        width: 175px !important;
        height: 35px !important;
        font-size: 12px;
        margin-right: 15px;
        margin-top: unset;
      }

      .welcome-link {
        margin-top: unset;
      }
    }
  }

  .top-matches-container {
    height: 425px !important;
  }

  .model-wrapper {
    .tips-popup {
      max-height: 650px !important;

      .card-img {
        height: 156px !important;
        margin-bottom: 20px !important;
      }

      .tips-description {
        max-height: 310px !important;
      }

      .sub-title {
        margin-bottom: 0px !important;
      }

      .tips-links {
        margin-bottom: 0px !important;
      }
    }
  }
}

@media only screen and (max-height: 720px) {
  .detail-div {
    height: 130px !important;
  }

  .more-filter-height,
  .top-matches-container {
    height: 350px !important;
  }

  .custom-prop-item p {
    width: 80%;
  }
}

@media only screen and (max-width: 650px) {
  .forgot-password-container .email-verify-container {
    width: 90%;

    .mail-send-btn {
      width: 100%;
    }
  }

  .email-verify-container {
    padding: 5%;

    .verify-title {
      font-size: 25px;
    }

    .login-input-box {
      width: 100%;
    }
  }

  .landing-page-signup,
  .contact-iframe-container {
    .first-part {
      width: 100%;
    }

    .second-part {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 360px) {
  .landing-page-signup .first-part {
    padding: 20px;
  }
}
