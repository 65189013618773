* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: $full-height;
  font-family: $font;
}

.App {
  // overflow-x: hidden;
}

.tips-description,
.filter-data-container,
.scroll-container {
  &::-webkit-scrollbar {
    width: 10px;
    height: 18px;
    background-color: $white-color;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: $white-color;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: #cecece;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.text--primary {
  color: $primary-color;
}

.height-full {
  height: $full-height;
}

a {
  &:focus {
    outline: none !important;
  }

  &:hover {
    text-decoration: none;
  }
}

.text-secondary {
  color: $primary-color !important;
}

.color-green {
  background-color: $secondary-color;
  border-color: $secondary-color;
  color: $white-color;
  font-weight: $font-bold;
}

.color-red {
  background-color: #d84747;
  border-color: #d84747;
  color: $white-color;
  font-weight: $font-bold;
}

.pointer {
  cursor: pointer;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-size: 14px;
  line-height: 1.7;
  margin: 0px;
}

.m--0-auto {
  margin: 0 auto !important;
}

.color-purple {
  background-color: $primary-color;
  color: $white-color;
}

input[type="checkbox"] {
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 6px;
  background-clip: content-box;
  border: 1.5px solid #bbbbbb;
  border-radius: 8px;
}

.sort-container {
  .sort-menu-div {
    width: 87%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.logo-svg {
  .st0 {
    fill: $immozy-lightblue;
  }

  .st1 {
    fill: $primary-color;
  }
}

.object-fit-cover {
  object-fit: cover;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-t-5 {
  padding-top: 5px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-32 {
  margin-top: 32px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.full-width {
  width: 100% !important;
}

.text-white {
  color: $white-color;
}

.text-black {
  color: $black-color;
}

.cursor-pointer {
  cursor: pointer;
}

.input-range__track--active {
  background: $primary-color !important;
}

.btn-arrow {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white-color;
  border: 1px solid $border-grey;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0);

  svg {
    width: 12px;
  }

  &:hover {
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.15);
  }
}

.primary-btn {
  background-color: $primary-color;
  color: $white-color;
  border-color: $primary-color !important;

  &:hover {
    background-color: $white-color !important;
    color: $primary-color !important;
  }
}

.primary-outline-btn {
  background-color: $white-color;
  color: $primary-color;
  border-color: $primary-color !important;

  &:hover {
    background-color: $primary-color !important;
    color: $white-color !important;
  }
}

.truncate--lines-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.w-53 {
  width: 53.2%;
}

.first-part {
  width: 50%;
  padding: 30px 15px;
  height: 600px;
  min-height: 485px;

  .custom-checkbox label::before {
    // position: absolute;
    content: "";
    top: 3px;
    margin-right: 8px;
    left: 0;
    min-width: 16px;
    height: 16px;
    background-color: $white-color;
    border: 1px solid $primary-color;
    border-radius: 2px;
  }

  .custom-checkbox.checked label::after {
    position: absolute;
    content: "\f00c";
    font-family: "FontAwesome";
    top: calc(50% - 12px);
    // left: 0px;
    width: 10px;
    height: 10px;
    text-align: center;
    font-size: 16px;
    line-height: 25px;
    color: $primary-color;
  }

  .logo-img-popup {
    width: 90px;
    margin: 0 auto;
  }

  .guest-headline {
    font-size: 30px;
    line-height: 44px;
    // text-transform: uppercase;
    text-align: left;
    font-weight: $font-bold;
    margin-top: 5%;
    margin-bottom: 10px;
    color: $black-color;
  }

  .logo-text-popup {
    text-align: left;
    font-size: 18px;
    color: $black-color;
    line-height: 25px;
    // font-weight: $font-bold;
    margin-bottom: 32px;
  }

  .login-form-popup {
    // margin: 0 25px;
    width: 100%;

    .form-group {
      margin: 24px 0;
    }

    .pwd-label,
    .pwd-icon {
      display: none;
    }

    .pwd-div {
      &:last-child {
        margin-right: 0px !important;
      }
    }

    .eye-icon-popup {
      top: 34px;
      font-size: 17px;
    }

    .login-label-popup {
      font-size: 16px;
      color: $grey-dark;
      letter-spacing: 0px;
      line-height: 16px;
      font-weight: $font-bold;
      margin: 8px 0;
    }

    .login-input-box {
      width: 100%;
      font-size: 15px !important;
      color: $grey-7 !important;
      letter-spacing: 0px;
      height: 44px;
      border: 1px solid $grey-5 !important;
      // border-radius: 22px;
      padding: 13px 12px;
      outline: none !important;
      border-radius: 2px;
    }
  }

  .text-inverse-popup {
    line-height: 22px;
    font-size: 12px;
    font-weight:$font-regular;
    user-select: none;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0px;

    a {
      font-size: 12px !important;
    }
  }

  .custom-checkbox {
    padding: 0;
    // padding-left: 24px !important;
  }

  .forget-password {
    color: $primary-color;
    font-weight: $font-regular;
    font-size: 14px;
    line-height: 24px;
  }

  // .checkbox-fade,
  // .checkbox-zoom {
  //   margin-right: -20px !important;
  // }

  .checkbox-fade label {
    line-height: 22px;
    font-size: 12px !important;
  }

  .forward-btn {
    height: 60px !important;
    // width: 216px !important;
    margin: 0 auto;
    font-size: 16px;
    line-height: 24px;
    font-weight: $font-bold;
    letter-spacing: 1px;
    text-transform: capitalize;
    width: 100%;
  }

  .submit-btn-popup {
    margin: 45px 0 10px 0;
    width: 100%;

    .divider-signup {
      border-right: 1px solid #cecece;
      margin: 0px 30px;
    }

    .signup-with {
      font-size: 16px;
    }

    .login-google {
      height: 46px;
      width: 46px;
      border-radius: 50% !important;
      padding: 0 !important;
      box-shadow: none !important;
      border: 1px solid grey !important;
      margin-right: 15px;

      &>div {
        margin-left: 3px !important;
        background: transparent !important;
      }
    }

    .kep-login-facebook {
      height: 46px;
      width: 46px;
      border-radius: 50% !important;
      padding: 0 !important;

      .fa {
        margin-right: 0px;
      }
    }
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .bottom-text-popup {
    font-size: 16px;
    font-weight:$font-regular;
    color: $black-color;
    text-align: center;
    line-height: 21px;

    button {
      color: $primary-color !important;
      font-weight: bold;
    }

    a {
      text-decoration: underline;
      color: $immozy-dark !important;
    }
  }

  .noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  .submit-btn {
    width: 100% !important;
    padding: 20px 0;
    height: auto !important;
    border-radius: 50px !important;
  }

  .error-msg-label {
    font-size: 14px;
    margin-bottom: 0;
    height: 30px;
    margin-top: 7px;
    font-weight: $font-bold !important;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.second-part {
  // background-image: url("../images/Signup_1.jpg");
  background-color: $primary-color;
  background-position: 80% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  border-top-right-radius: 5px;

  .first-para {
    color: $bg-white;
    margin: 0 auto;
    margin-top: 45px;
    margin-bottom: 20px;
    margin-left: 30px;

    &:first-of-type {
      margin-top: 85px;
    }

    .big-text {
      font-size: 35px;
      line-height: 40px;
      font-weight: $font-bold;
    }

    .small-text {
      font-size: 14px;
      letter-spacing: 0.05em;
      line-height: 20px;
      font-weight: $font-light;
    }
  }

  .close-btn-popup {
    border: 1px solid $white-color;
    border-radius: 50%;
    float: right;
    margin: 10px;
    cursor: pointer;
  }

  .floating-logo {
    position: absolute;
    margin-top: -20px;
    margin-left: 180px;
    width: 112px;
  }

  .slider-wrapper {
    @extend .full-width;
    height: auto;
    margin: auto;
    color: $white-color;

    * {

      &:focus,
      &:focus-visible,
      &:focus-within {
        outline: none !important;
      }
    }

    .slide {
      img {
        margin-bottom: -10px;
      }

      .big-text {
        font-size: 26px;
        line-height: 36px;
        font-weight: $font-bold;
        max-width: 85%;
      }

      .small-text {
        margin-top: 8px;
        margin-bottom: 32px;
        font-size: 16px;
        max-width: 85%;
        text-align: center;
        line-height: 22px;
        font-weight: $font-light;
      }
    }

    .slick-dots {
      li {
        margin: 0 0.25rem;

        button {
          display: block;
          width: 8px;
          height: 8px;
          padding: 0;

          border: none;
          border-radius: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          text-indent: -9999px;
        }
      }

      li.slick-active button {
        background-color: rgb(255, 255, 255);
      }
    }
  }

  .transparent-slide {
    position: absolute;
    top: 0;
    left: 0;

    .transparent-slide-1,
    .transparent-slide-2 {
      background-color: $white-color;
      opacity: 0.5;
      border-radius: 15px;
      top: 0;
    }

    .transparent-slide-1 {
      height: 200px;
      width: calc(50% - 40px);
      transform: translate(0px, -10px);
    }

    .transparent-slide-2 {
      position: absolute;
      height: 200px;
      width: calc(50% - 80px);
      transform: translate(0px, -20px);
    }
  }
}

.red-bottom {
  height: 49px;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  place-content: center;
  place-content: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  p {
    font-size: 16px;
    letter-spacing: 0.06px;
    color: $bg-white;
    text-align: center;
  }

  .p1 {
    font-weight: $font-bold;
  }
}

.welcome-class>div>div>div:first-child {
  border-radius: 22px !important;
}

.Welcome-popup-container {
  .close-btn-popup {
    border: 1px solid $white-color;
    border-radius: 50%;
    float: right;
    margin: 10px;
    cursor: pointer;
  }

  .welcome-popuup-bg {
    background-image: url("../images/Picture.png");
    width: 100%;
    height: 720px;
    background-repeat: no-repeat;
    background-position-y: -5px;
    border-radius: 18px !important;
  }

  .welcome-imozy {
    font-size: 35px;
    font-weight: bold;
    color: $primary-color;
    text-align: center;
    line-height: 45px;
    font-weight: $font-bold;
    margin: 15px 0;
  }

  .text-welcome-div {
    position: absolute;
    top: 360px;

    .welcome-details {
      font-size: 17px;
      font-weight:$font-regular;
      color: $immozy-dark;
      text-align: center;
      line-height: 23px;
      text-align: center;
      padding: 5px 4%;
    }

    .welcome-btn-section {
      flex-direction: column;
    }

    .welcome-btn {
      width: 235px !important;
      height: 49px !important;
      margin-top: 15px;
      text-transform: capitalize;
      font-size: 16px;
    }

    .welcome-link {
      color: $immozy-dark;
      text-align: center;
      line-height: 20px;
      text-decoration: underline;
      margin-top: 12px;
      cursor: pointer;
    }

    .condition-text {
      font-size: 10px;
      text-align: center;
      margin-top: 10px;
    }
  }
}

.new-contact-page {
  #inputID {
    margin-right: 0 !important;
    width: 100% !important;
  }

  .first-part {
    .forward-btn {
      width: 250px;
    }
  }
}

.new-contact-page,
.landing-page-signup {
  .load-wait {
    position: absolute;
    top: -22px;
  }

  .logo-immozy {
    width: 85px;
    position: absolute;
    top: 25px;
  }

  .onboarding-spinner-wrapper {
    z-index: 1;
  }

  .first-part {
    width: 50%;
    padding: 10px 40px;
    height: unset;
    margin-top: 100px;

    .forward-btn {
      width: 250px;
    }

    .guest-headline {
      margin-top: 0;
    }

    .login-form-popup .login-input-box {
      height: 50px !important;
      border: 1px solid $grey-5;
    }

    // .custom-checkbox label::before,
    // .custom-checkbox.checked label::after {
    //   top: 14px;
    // }
  }

  .pwd-div {
    width: 24%;
    // margin-right: 2%;
  }

  .second-part {
    min-height: 100vh;
  }
}

.unsub-block {
  background-color: $white-color;

  svg {
    width: 100px;
  }
}

.contact-iframe-container {

  .floating-logo,
  .opt-in-checkbox,
  .close-btn-popup {
    display: none !important;
  }

  .second-part {
    min-height: 100vh;
  }

  .first-part {
    height: unset;
    margin-top: 20px;
    padding: 0px 5% 20px 5%;
    overflow: hidden;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .login-form-popup .login-input-box {
      height: 50px !important;
      border: 1px solid $grey-5 !important;

      &.error-border {
        border: 1px solid $hover-red !important;
      }
    }
  }

  .reach-select-container{
   
     .error-border {
        border: 1px solid $hover-red !important;
      }
  }

  .second-part .slider-wrapper {
    .slide img {
      margin-bottom: -70px;
    }

    .slide .small-text {
      max-width: 70%;
    }
  }

  .new-interested-popup .first-part {
    .btn-new-int {
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }

    .long-desc-text {
      font-size: 12px;
    }

    #inputID {
      width: 95%;
      margin-right: 2%;
    }
  }

  .success-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;

    p {
      font-weight: $font-regular;
      margin: 10px 0;

      &:nth-child(2) {
        font-size: 16px;
      }
    }
  }
}

.mr-15px {
  margin-right: 15px;
}

.mr-6px {
  margin-right: 6px;
}

.mb-6px {
  margin-bottom: 6px;
}

//------------------------------------------------------------------------------------------------------------

// Progress bg colours
$pg-default-bg: #EEE;
// Progress bar bg colours
$bar-default-bg: $primary-color;
$bar-info-bg: #9575cd;
$bar-danger-bg: #ef5350;
$bar-success-bg: #66bb6a;
$bar-warning-bg: #ffb74d;

// ADDS A BROWSER PREFIX TO THE PROPERTY
@mixin browser_prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

// KEYFRAMES
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

// ANIMATION
@mixin animation($str) {
  @include browser_prefix('animation', $str);
}

@mixin cssProgressGlowActive($name, $color) {
  @include keyframes($name) {

    0%,
    100% {
      box-shadow: 5px 0px 15px 0px #{$color};
    }

    45% {
      box-shadow: 1px 0px 4px 0px #{$color};
    }
  }
}

/* -----------------------------------------------------
	CSS Progress Bars
-------------------------------------------------------- */
.cssProgress {
  width: 100%;

  .progress1,
  .progress2,
  .progress3 {
    position: relative;
    overflow: hidden;
    width: 100%;
    font-family: "Roboto", sans-serif;
  }

  .cssProgress-bar {
    display: block;
    float: left;
    width: 0%;
    height: 100%;
    background: $bar-default-bg;
    box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.1);
    transition: width 0.8s ease-in-out;
  }

  .cssProgress-label {
    position: absolute;
    overflow: hidden;
    left: 0px;
    right: 0px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.7em;
    text-align: center;
    text-shadow: 0px 1px rgba(0, 0, 0, 0.3);
  }

  .cssProgress-info {
    background-color: $bar-info-bg !important;
  }

  .cssProgress-danger {
    background-color: $bar-danger-bg !important;
  }

  .cssProgress-success {
    background-color: $bar-success-bg !important;
  }

  .cssProgress-warning {
    background-color: $bar-warning-bg !important;
  }

  .cssProgress-right {
    float: right !important;
  }

  .cssProgress-label-left {
    margin-left: 10px;
    text-align: left !important;
  }

  .cssProgress-label-right {
    margin-right: 10px;
    text-align: right !important;
  }

  .cssProgress-label2 {
    display: block;
    margin: 2px 0;
    padding: 0 8px;
    font-size: 0.8em;

    &.cssProgress-label2-right {
      text-align: right;
    }

    &.cssProgress-label2-center {
      text-align: center;
    }
  }

  .cssProgress-stripes,
  .cssProgress-active,
  .cssProgress-active-right {
    background-image: linear-gradient(-45deg,
        rgba(255, 255, 255, .125) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, .125) 50%,
        rgba(255, 255, 255, .125) 75%,
        transparent 75%,
        transparent);
    background-size: 35px 35px;
  }

  .cssProgress-active {
    @include animation('cssProgressActive 2s linear infinite');
  }

  .cssProgress-active-right {
    @include animation('cssProgressActiveRight 2s linear infinite');
  }

  @include keyframes('cssProgressActive') {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 35px 35px;
    }
  }

  @include keyframes('cssProgressActiveRight') {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: -35px -35px;
    }
  }
}

/* -----------------------------------------------------
	Progress Bar 1
-------------------------------------------------------- */
.progress1 {
  background-color: $pg-default-bg;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2);

  .cssProgress-bar {
    height: 18px;
  }

  .cssProgress-label {
    line-height: 18px;
  }
}

/* -----------------------------------------------------
	Progress Bar 2
-------------------------------------------------------- */
.progress2 {
  background-color: $pg-default-bg;
  border-radius: 9px;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2);

  .cssProgress-bar {
    height: 18px;
    border-radius: 9px;
  }

  .cssProgress-label {
    line-height: 18px;
  }
}

/* -----------------------------------------------------
	Progress Bar 3
-------------------------------------------------------- */
.progress3 {
  width: auto !important;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;

  .cssProgress-bar {
    height: 16px;
    border-radius: 3px;
  }

  .cssProgress-label {
    line-height: 16px;
  }
}

/* -----------------------------------------------------
	Progress Bar 4
-------------------------------------------------------- */
.progress4 {
  position: relative;
  width: 100%;
  background-color: $pg-default-bg;

  &.cssProgress-bg {
    background-color: #bbdefb !important;
  }

  &.cssProgress-bg-info {
    background-color: #d1c4e9 !important;
  }

  &.cssProgress-bg-danger {
    background-color: #ffcdd2 !important;
  }

  &.cssProgress-bg-success {
    background-color: #c8e6c9 !important;
  }

  &.cssProgress-bg-warning {
    background-color: #ffecb3 !important;
  }

  .cssProgress-bar {
    display: block;
    float: none;
    width: 0%;
    height: 4px;
    background: $bar-default-bg;

    &.cssProgress-lg {
      height: 6px;
    }

    &.cssProgress-2x {
      height: 8px;
    }

    &.cssProgress-3x {
      height: 10px;
    }

    &.cssProgress-4x {
      height: 6px;
    }

    &.cssProgress-5x {
      height: 14px;
    }
  }
}

//--------------------------------------------- custom file upload --------------------------------------

.custom-file-upload-wrapper {

  .upload-file-header {
    margin-bottom: 24px;
  }

  .upload-file-sub-text {
    margin-bottom: 24px;
    font-style: normal;
    font-weight: 420;
    font-size: 20px;
    line-height: 30px;
    color: black;
  }

  .drop_area {
    width: 500px;
    height: 300px;
    border-radius: 8px;
    border: 2px dashed $grey-5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s ease-in-out all;

    .upload-text {
      font-size: 18px;
    }

    .file-upload-label {
      font-size: 20px;
      font-weight: bold;
      font-family: $font;
    }

    .file-upload-description {
      font-size: 20px;
      color: $grey-7;
      font-family: $font;
      margin-bottom: 15px;
    }

    .btn {
      border-radius: 50px;
      border: 2px solid $primary-color;
      padding: 7px 20px;
    }

    &:hover {
      box-shadow: 0px 0px 15px rgba(219, 219, 219, 0.5);
    }
  }
}

//------------------------- something-went-wrong-element --------------------------------------

.something-went-wrong-element {
  height: 100%;
  min-height: inherit;
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


/******************************************************************************/
/* Password assistent *********************************************************/
/******************************************************************************/

.success-color {
  color: $green;
}

.danger-color {
  color: $primary-color;
}

.password-constraint-icon {
  padding-right: 10px;
}

.popover {
  max-width: 400px;

  .popover-body {
    width: max-content;
    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }
}

//------------------- currency-input-style -----------------------------

.currency-input-style-wrapper {
  border-radius: 13px;
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid #ced4da;
  padding: 5px 10px;
  min-width: 400px;

  &:focus,
  &:focus-within,
  &:focus-visible {
    border: 2px solid $primary-color !important;
    box-shadow: 0 0 0 3px #9328493d;
  }

  .option-input-label {
    font-size: 16px;
    width: 100%;
    color: #5F5F5F;
    margin-left: 5px;
  }

  input,
  .currency-input-style {
    border-radius: 13px;
    font-size: 18px !important;
    color: #495057;
    border: none !important;
    padding: 0 !important;
  }
}

.currency-input-style-wrapper.disabled {
  background-color: #ededed;

  .option-input-label {
    margin-left: 5px;
    font-weight: $font-bold;
  }

  input,
  .currency-input-style {
    background-color: #ededed;
    color: #8f8f8f !important;
  }
}

.finance-options-container {

  .currency-input-style-wrapper input,
  .currency-input-style-wrapper .currency-input-style {
    padding: 0 !important;
  }
}

//---------------------------------- custom toast styling -------------------------------------

.Toastify__toast-container {
  margin-bottom: 4rem;

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: #65C239 !important;
  }
}

//---------------------------------------- telephone-input -------------------------------------------

.react-tel-input {
  .special-label {
    display: none;
  }

  .flag-dropdown {
    border: 1px solid #d9d9d9;

  }

  .flag-dropdown {
    background-color: $white-color;
  }

  .react-tel-input-disabled,
  :disabled {
    background-color: #e9ecef;
  }
}

//---------------------------------- badge styles ---------------------------------------------------

.badge-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $white-color;
  border-radius: 4px;
  border: 1.5px solid #CACED2;
  padding: 0 5px;

  svg {
    margin-right: 5px;
  }
}