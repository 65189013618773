@import "~assets/css/variable.scss";

.immozy-home-container {
  max-width: 1200px;
  margin: 0 auto;
  // background-color: $grey-6;
  min-height: calc(100vh - 180px);

  // .floating-card {
  //   z-index: 1;
  //   position: fixed;
  //   padding: 4px;
  //   box-sizing: border-box;
  //   color: $white-color;
  //   right: 0;
  //   line-height: 41px;
  //   padding: 5px 15px;
  //   height: 51px;
  //   top: 113px;
  //   background: $primary-color;

  //   a {
  //     color: $white-color;
  //   }
  // }

  .intro-section {
    padding: 3%;
    padding-bottom: 1%;
    width: calc(100% - 280px);

    .name {
      font-size: 26px;
      font-weight: bold;
    }

    .description {
      font-style: normal;
      font-weight:$font-regular;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.01em;
    }

    .profile-details {
      width: 35%;
      margin-right: 3%;
    }

    .progress-details {
      width: 50%;

      .CircularProgressbar {
        background-color: white;
        padding: 20px;
        box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
        border-radius: 50%;
        width: 220px;
        height: 220px;
        margin: 10px 0 32px 0;

        .CircularProgressbar-text {
          font-weight: 900;
          font-size: 20px !important;
          dominant-baseline: central;
        }
      }

      .score-chances-label {
        height: 46px;
        width: auto;
        padding: 10px 16px;
        border-radius: 50px;
        margin-bottom: 16px;
      }

      .obj-link {
        font-size: 14px;
        margin-left: 10px;
        text-decoration: underline;
        text-align: center;
      }
    }
  }

  .collapse-container {
    padding: 30px;
    margin: 0 auto;
    position: relative;
    padding-top: 0;

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    .title {
      font-weight:$font-regular;
      font-size: 20px;
      font-weight: bold;
      line-height: 31px;
    }
  }
}

.transparent-bg {
  background: transparent;
}

.immozy-home-action-btn {
  padding: 20px 60px;
  width: 410px;
  height: 64px;
  background: $primary-color;
  border-radius: 40px;
  color: $white-color;

  &:disabled {
    background: #f5f5f5;
    border: 1px solid #caced2;
    color: #444;
    opacity: 1;
  }
}

.success-label {
  color: #149914;
  text-align: center;
}

.rotate-180 {
  transform: rotate(180deg);
}

.floating-card {
  background-color: $white-color;
  // box-shadow: 0 0 15px rgba(212, 212, 212, 0.391);
  box-shadow: 0px 10px 60px rgba(219, 219, 219, 0.5);
  border-radius: 10px;
  padding: 15px;
  width: 288px;
  margin-top: 11px;
  margin-left: 15px;
  position: sticky;
  top: 100px;
  max-height: 522px;
  // right: 20px;
  // bottom: 100px;

  .total-progress-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
    padding-bottom: 10px;
    height: 230px;
    transition: all 0.4s ease-in-out;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  .hide-total-progress {
    opacity: 0 !important;
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
    transform: scale(0);
  }

  .label {
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .object-card {
    // border: 0.92029px solid #CACED2;
    border-radius: 7px;
    // padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .label {
      margin-top: 5px;
    }


    .image-wrapper {
      position: relative;
      width: 100%;

      .score-badge {
        position: absolute;
        top: 7px;
        right: 7px;
        height: 50px;
        width: 50px;
        color: $white-color;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        border: 2px solid $white-color;
      }

      img {
        border-radius: 7px;
        width: 100%;
        margin-bottom: 18px;
        max-height: 150px;
        object-fit: cover;
      }
    }

    .go-to-object-btn {
      margin-bottom: 1%;
      letter-spacing: 0.5px;
      background: $white-color;
      border: 1px solid $grey-for-button;
      border-radius: 40px;
      padding: 5px 25px;
      font-weight: bold;
      color: #000000A6;
      transition: 0.3s all ease-in-out;

      &:hover {
        background-color: $primary-color;
        color: $white-color;
      }
    }
  }
}



    .accordion-item {
      margin: 12px 0;
      border-radius: 10px;
      // overflow: hidden;
      box-shadow: 0px 10px 60px rgba(219, 219, 219, 0.5);

      .accordion-header {
        position: relative;
        width: 100%;
        display: flex;
        height: 70px;
        align-items: center;
        padding: 15px;
        font-size: 16px;
        color: $black-color;
        line-height: 21px;
        background-color: $white-color;
        border-radius: 10px;

        .header-content {
          padding: 0 10px;

          .left {
            font-weight: 600;

            .icon {
              .verified-badge {
                position: absolute;
                bottom: -8px;
                right: -3px;
              }
            }
          }

          .right {
            .progress-detail {
              font-weight: bold;
              letter-spacing: 1px;

              .importance-level-badge {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 2px 10px;
                border-radius: 4px;
              }
            }
          }
        }

        .bottom-border-radius {
          border-radius: 0 0 20px 20px !important;
          overflow: hidden;
          transition: 0.2s all ease-in-out;
        }

        .accordion-header-progress-bar {
          position: absolute;
          bottom: 0;
          border-radius: 0;
          left: 0;
          transition: 0.2s all ease-in-out;
          width: 100%;
        }

        .header-icon {
          height: 50px;
          width: 50px;
          background-color: $border-grey;
          color: $white-color;
          border-radius: 8px;
          fill: $white-color;
          padding: 5px;
        }

        .arrow-icon {
          // position: absolute;
          right: 10px;
          line-height: 13px;
          border-radius: 50%;
          color: $white-color;
          font-size: 16px;
          transition: 0.2s all ease-in-out;
        }
      }

      .accordion-body {
        padding: 50px;
        background-color: $white-color;
        border-radius: 0 0 10px 10px;

        .personal-info-form-wrapper,
        .onboarding-section-wrapper {
          .form-title {
            font-size: 18px;
            font-weight: $font-bold;
            margin-bottom: 20px;
          }

          .form-group {
            margin-bottom: 24px;

            &>label {
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 8px;
            }

            .form-control {
              box-shadow: none;
              border: 1px solid $grey-5;
              padding: 13px 12px;
              width: 100%;
              border-radius: 2px;
              color: rgba(0, 0, 0, 0.65);
              font-weight:$font-regular;
              font-size: 16px !important;
              line-height: 24px;

              &.uppercase {
                text-transform: uppercase;
              }

              &:focus {
                background: transparent;
              }
            }
          }

          .calendar-icon-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;

            &>div {
              height: 100%;
              display: flex;
              justify-content: flex-end;
              margin-right: 20px;
              align-items: center;
            }
          }
        }

        .mb-60px {
          margin-bottom: 60px;
        }

        .mb-30px {
          margin-bottom: 30px;
        }

        .full-border-radius {
          border-radius: 1000px;
        }

        // .overflow-hidden {
        //   overflow: hidden;
        // }

        .width-500px {
          width: 500px;
        }

        .qualify-buttons {
          border-radius: 1000px;
          padding: 20px 60px !important;
        }

        .hint-note-wrapper {
          // display: flex;
          // max-width: 463px;
          width: auto;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          padding: 18px 20px;
          background-color: rgba(237, 190, 89, 0.15);
          border-radius: 20px;
          margin-bottom: 50px;
          text-align: left;

          .note-heading {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 13px;
            line-height: 22px;
          }

          p {
            font-size: 16px;
            line-height: 19px;
          }
        }

        .uploading-file-wrapper {

          .delete-icon,
          .image-icon {
            min-height: 65px;
            max-height: fit-content;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .delete-icon {
            justify-content: flex-end;
            cursor: pointer;
          }
        }
      }
    }


.full-height {
  height: inherit;
}

.profile-accordian {
  display: inline-block;
  // width: 100%;
  width: calc(100% - 280px);
}

@media only screen and (max-height: 768px) {
  .profile-accordian {
    width: calc(100% - 280px);
  }

  .floating-card {
    // bottom: 20px;
    // top: unset;
    width: 260px;
    margin-bottom: 10px;

    .label {
      font-size: 14px;
      margin-bottom: 5px;
      width: 235px;
    }

    .total-progress-wrapper {
      height: 120px;
      margin-bottom: 0;
    }
  }
}