@import "~assets/css/variable.scss";

.custom-checkbox-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    // &:hover input~.checkmark {
    //     background-color: #ccc;
    // }

    /* Create a custom checkbox */
    .checkmark {
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        overflow: "hidden";
        min-width: 16px;
        margin-right: 10px;
        // background-color: #eee;
        border: 1px solid $grey-5;

        /* Create the checkmark/indicator (hidden when not checked) */
        &::after {
            content: "";
            display: none;
        }

        /* Style the checkmark/indicator */
        &::after {
            width: 5px;
            height: 8.5px;
            border: solid $primary-color;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    /* When the checkbox is checked, add a background */
    input:checked~.checkmark {
        border-color: $primary-color;
    }


    /* Show the checkmark when checked */
    input:checked~.checkmark:after {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -3px 0 0 -0.5px;
    }

}



// .custom-checkbox-container {
//     display: block;
//     position: relative;
//     padding-left: 35px;
//     margin-bottom: 12px;
//     cursor: pointer;
//     font-size: 22px;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }

// /* Hide the browser's default checkbox */
// .container input {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//     height: 0;
//     width: 0;
// }

// /* Create a custom checkbox */
// .checkmark {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 16px;
//     border-radius: 5px;
//     overflow: "hidden";
//     width: 16px;
//     background-color: #eee;
// }

// /* On mouse-over, add a grey background color */
// .container:hover input~.checkmark {
//     background-color: #ccc;
// }

// /* When the checkbox is checked, add a background */
// .container input:checked~.checkmark {
//     background-color: $primary-color;
// }

// /* Create the checkmark/indicator (hidden when not checked) */
// .checkmark:after {
//     content: "";
//     position: absolute;
//     display: none;
// }

// /* Show the checkmark when checked */
// .container input:checked~.checkmark:after {
//     display: block;
// }

// /* Style the checkmark/indicator */
// .container .checkmark:after {
//     left: 0;
//     top: 0;
//     width: 4px;
//     height: 9px;
//     border: solid white;
//     border-width: 0 3px 3px 0;
//     -webkit-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     transform: rotate(45deg);
// }