@import "~assets/css/variable.scss";

.contact-form {
  position: relative;
  width: 1060px;
  margin: 0 auto;
  background-color: $white-color;
  border-radius: 12px;

  .logo-header {
    position: absolute;
    margin-top: 10px;
    margin-left: 25px;
  }

  .contact-header {
    height: 80px;
    place-content: center;
    box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.04);

    .contact-title {
      font-size: 24px;
      font-weight: $font-bold;
      color: $secondary-color;
      text-align: center;
      line-height: 20px;
      text-align: center;
    }
  }

  .back {
    font-size: 16px;
    color: $grey-light;
    letter-spacing: 0px;
    cursor: pointer;
    text-decoration: underline;
    margin-left: -20px;
  }

  .later-label {
    font-size: 16px;
    color: $secondary-color;
    letter-spacing: 0px;
    font-weight: $font-light;
    text-decoration: underline;
    cursor: pointer;
    text-transform: lowercase;
    position: absolute;
    bottom: 20px;
    margin: 0 auto;
    left: 24%;
  }

  .white-label {
    color: $white-color;
    user-select: none;
  }

  .bottom-bar {
    margin-top: 15px;
    display: inline-flex;
  }
}

.close-btn-calendly {
  border-radius: 50%;
  margin: 10px;
  cursor: pointer;
  background-color: $primary-color;
  position: absolute;
  right: 10px;
}

.calendly-inline-widget {
  height: 810px !important;
}

.calendly-title {
  text-align: center;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: -45px;
  color: $primary-color;
}

.realtor-name {
  font-weight: $font-bold;
}

//--------------------------------------- new- contact-pop-up-design -----------------------------------

.over-line {
  // text-decoration: line-through;
  color: $green;
}

.contact-seller-popup,
.contact-pop-up-wrapper {
  position: relative;
  width: 1060px;
  margin: auto;
  background-color: $white-color;
  border-radius: 20px;
  overflow: hidden;

  .close-btn {
    border-color: $white-color;
    position: absolute;
    right: 24px;
    top: 26px;
    height: 18px;
    width: 18px;
    cursor: pointer;

    // svg {
    //   transition: transform 0.2s ease-in-out;
    // }

    &:hover {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  .right-pannel,
  .left-pannel {
    width: calc(100% / 2);
  }

  .left-pannel {
    padding: 25px 63px;
    position: relative;

    .loader-wrapper {
      position: absolute;
      z-index: 1;
      width: 100%;
      background: #ffffffb3;
      height: 100%;
      top: 0;
      display: flex;
      left: 0;
      justify-content: center;
      align-items: center;
    }

    .realtor-name {
      font-weight: $font-bold;
    }

    .show-realtor-info {
      margin-top: 0.25rem !important;
      line-height: 1;
      font-weight: 500;
      overflow: visible;
      transition: 0.2s all ease-in-out;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 36px;
    }

    .hide-realtor-info {
      font-weight: 500;
      margin-top: 0 !important;
      line-height: 0 !important;
      overflow: hidden;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 36px;
      transition: 0.2s all ease-in-out;
    }

    .contact-form {
      width: 100%;
    }

    .contact-form,
    .offer-container {
      .offer-title {
        font-size: 32px;
        font-weight: $font-bold;
        margin-bottom: 10px;
      }

      .progress-in-steps {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
      }

      .offer-desc {
        font-style: normal;
        font-weight:$font-regular;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 24px;

        a {
          color: #000000a6;
          transition: 0.3s color ease-in-out;
          font-weight: bold;
          cursor: pointer;
          margin: 0 5px;

          &:hover {
            color: #000000;
          }
        }
      }

      .completion-steps-checkmark-wrapper {
        margin-bottom: 250px;

        .step {
          margin-bottom: 8px;

          & > svg {
            margin-right: 16px;
          }

          .step-text {
            margin: 0 12px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .contact-seller-instruction-label {
        padding: 20px;
        // background-color: $transparent-green;
        border: 1px solid /* $green */;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 100px;

        font-style: normal;
        font-weight:$font-regular;
        font-size: 12px;
        line-height: 14px;

        & > svg {
          margin-right: 12px;
        }
      }

      .contact-seller-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a:hover {
          color: $white-color;
        }

        .forward-btn {
          width: auto;
          height: auto;
          padding: 20px 46px;
          font-style: normal;
          font-weight: $font-bold;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 20px;
          text-transform: none;
        }

        .contact-realtor-btn {
          padding: 20px 60px;
          width: 410px;
          height: 64px;
          background: #f5f5f5;
          border: 1px solid #caced2;
          border-radius: 40px;
          margin-bottom: 20px;
          color: #444;
          transition: 0.3s all ease-in-out;

          &:hover,
          &.active {
            background-color: $primary-color;
            color: $white-color;
          }

          &:disabled {
            background: #f5f5f5;
            cursor: not-allowed;
            color: #444;
            opacity: 0.7;
          }
        }
      }

      .personal-info-form-wrapper {
        .form-group {
          margin-bottom: 24px;

          label {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 8px;
          }

          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }

          .form-control {
            box-shadow: none;
            border: 1px solid $grey-5;
            padding: 12px;
            height: max-content;
            width: 100%;
            border-radius: 2px;
            color: rgba(0, 0, 0, 0.85);
            font-weight:$font-regular;
            font-size: 14px !important;
            line-height: 24px;

            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: rgba(0, 0, 0, 0.65);
              /* Firefox */
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: rgba(0, 0, 0, 0.65);
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              color: rgba(0, 0, 0, 0.65);
            }
          }

          .btn {
            padding: 13px 12px;

            &:hover {
              background-color: $white-color !important;
              color: $primary-color !important;
            }
          }
        }
      }
    }
  }

  .right-pannel {
    background-color: $primary-color;
    color: $white-color;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 63px;

    .progress-details {
      .CircularProgressbar {
        padding: 20px;
        border-radius: 50%;
        width: 300px;
        height: 300px;
        margin: 10px 0 32px 0;

        .CircularProgressbar-text {
          font-weight: 900;
          font-size: 20px !important;
          dominant-baseline: central;
        }
      }
    }

    .profile-progress-heading {
      font-style: normal;
      font-weight: $font-bold;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px;
      text-align: center;
    }

    .profile-progress-advice {
      font-style: normal;
      font-weight:$font-regular;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
    }

    .immozy-check-heading {
      font-style: normal;
      font-weight: $font-bold;
      font-size: 32px;
      color: $white-color;
      margin-bottom: 10px;
    }

    .immozy-check-card {
      background-color: $white-color;
      color: $grey-light;
      padding: 25px;
      border-radius: 20px;
      width: 100%;

      .section-check {
        align-items: flex-start;
        display: flex;
      }

      .graph {
        height: 80px;
        width: 80px;
        margin-right: 30px;

        .CircularProgressbar-text {
          font-weight: $font-bold;
          font-size: 24px !important;
          line-height: 24px;
          text-align: center;
          color: $green;
        }
      }

      .score {
        position: relative;
        height: 80px;
        width: 80px;
        text-align: center;
        line-height: 77px;
        border-radius: 8px;
        font-size: 35px;
        color: $white-color;
        font-weight: 600;
        margin-right: 30px;
      }

      .score-text {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        // height: 80px;

        p {
          font-size: 20px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.65);
          font-weight: $font-bold;
          margin-bottom: 10px;
        }

        ul {
          li {
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.mb-12 {
  margin-bottom: 12px !important;
}

.check-option-for-contact-seller,
.check-option-for-profile-completion {
  .custom-radio,
  .custom-checkbox {
    padding: 0;

    .custom-control-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &::before,
      &::after {
        border-radius: 2px !important;
        height: 16px !important;
        width: 16px !important;
      }

      &::before {
        position: unset;
        margin-right: 8px;
      }

      &::after {
        left: 0;
        top: 4px;
      }
    }
  }

  .custom-radio {
    .custom-control-label {
      &::before,
      &::after {
        border-radius: 10px !important;
      }

      &::before {
        height: 20px !important;
        width: 20px !important;
        margin-right: 12px;
      }

      &::after {
        height: 10px !important;
        width: 10px !important;
        left: 5px;
        top: 7px;
      }
    }
  }
}

.tool-tip-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;

  &.personal-data {
    left: 115px;

    &.de {
      left: 150px;
    }
  }

  &.contact {
    left: 125px;

    &.de {
      left: 130px;
    }
  }

  .tool-tip-btn {
    background: transparent !important;
    padding: 0;
    height: auto;
    width: auto;
    border: none !important;
    outline: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;

    &:focus,
    &:focus-within,
    &:focus-visible,
    &:active,
    &:hover {
      background: transparent !important;
      padding: 0;
      box-shadow: none;
      border: none !important;
      outline: none !important;
    }
  }
}

.info-btn {
  position: relative;

  .tool-tip {
    position: absolute;
    bottom: 48px;
    font-size: 14px;
    // right: calc(50% - 10px);
    width: 255px;
    line-height: 1.3;
    // width: max-content;
    padding: 6px;
    background: rgba(0, 0, 0, 0.75);
    color: $white-color;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s all ease-in-out;
    pointer-events: none;

    &::after {
      position: absolute;
      bottom: -6px;
      content: "";
      right: calc(50% - 6px);
      border-top: 6px solid rgba(0, 0, 0, 0.75);
      border-right: 6px solid transparent;
      border-bottom: 0;
      border-left: 6px solid transparent;
    }
  }

  &:hover {
    .tool-tip {
      bottom: 28px;
      opacity: 1;
      visibility: visible;
    }
  }
}

.contact-seller-popup {
  .right-pannel {
    padding: 36px !important;
  }
}

.white-btn {
  border: 1px solid $grey-for-button;
  font-style: normal;
  font-weight: $font-bold;
  font-size: 14px !important;
  line-height: 24px;
  height: 64px;
  width: 185px;
  border-radius: 30px;
  font-size: 13px;
  color: #000000a6;
  text-align: center;
  letter-spacing: 0.06em;
  box-shadow: none !important;
  transition: all 0.3s ease;

  &:hover {
    border-color: black;
    color: black;
  }
}

.thanks-container {
  position: relative;
  width: 622px;
  height: 600px;
  margin: auto;
  background-color: $white-color;
  border-radius: 20px;
  overflow: hidden;

  .close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.platform-contact-popup {
  .platform-links {
    font-weight: $font-bold;
    color: $primary-color;
    margin: 15px 0;
    min-height: 75px;
    font-size: 21px;

    p {
      color: $primary-color !important;
      font-size: 24px !important;
    }
  }

  .platform-contact-header {
    font-size: 32px;
    font-weight: $font-bold;
    line-height: 38px;
  }

  .platform-contact-detail {
    font-size: 14px;
    line-height: 21px;
    margin-top: 20px;
  }

  .pdf-section {
    .pdf-title {
      font-size: 20px;
      font-weight: $font-bold;
    }
  }

  .copy-input {
    height: 40px;
    cursor: pointer;
  }

  .pdf-link {
    color: $primary-color;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }

  .copy-btn {
    height: 40px;
    border-radius: 0;
    background: black;
    color: white;
    font-size: 14px;
    cursor: pointer;
  }

  .pdf-btn {
    margin: 7% 0;
  }

  .platform-contact-footer {
    font-size: 11px;
    // position: absolute;
    // width: 40%;
    // bottom: 3%;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.65);
  }

  .right-pannel {
    padding: 40px !important;
    color: rgba(0, 0, 0, 0.85);

    .contact-form-heading {
      font-weight:$font-regular;
      font-size: 24px;
      line-height: 33px;
      text-align: center;
      color: $white-color;
      margin-bottom: 78px;
    }

    .contact-form-card {
      width: 100%;
      height: fit-content;
      padding: 30px 40px;
      border-radius: 20px;
      background-color: $white-color;

      label {
        margin-bottom: 8px;
      }

      .image-awatar-wrapper {
        height: 100px;
        width: 100%;
        border-radius: 50%;
        margin: -80px 0 20px 0;

        img {
          height: inherit;
          width: 100px;
        }
      }

      .form-control {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
      }
    }
  }
}

.scoring-list {
  margin-left: -5px;

  ul {
    list-style: none;

    li {
      list-style: none;
      // list-style-type: disc !important;
    }
  }
}

.CircularProgressbar-text {
  font-weight: 900;
  font-size: 20px !important;
  dominant-baseline: central !important;
}

.radio-form-label {
  font-size: 16px;
  letter-spacing: 0px;
  margin-bottom: 8px;
  color: #444;
}

.css-1vxblyv-singleValue,
.css-14el2xx-placeholder {
  font-size: 16px !important;
  color: #495057;
}

.error-border-round {
  .custom-control-label::before {
    border: 2px solid $hover-red !important;
  }
}

.contact-warning {
  width: 410px;
  height: 60px;
  background: #fffafa;
  border: 1px solid #e50c4f;
  border-radius: 4px;
  margin-bottom: 25px;

  &.contact-success {
    border: 1px solid $green;
    background: #f5fffa;

    .warning-contact-link {
      color: $green;
    }
  }

  img {
    height: 14px;
    width: 14px;
    margin: 5px 10px;
  }

  p {
    font-size: 12px;
    line-height: 16px;
  }

  .warning-contact-text {
    color: rgba(0, 0, 0, 0.85);
  }

  .warning-contact-link {
    color: $primary-color;
    margin-left: 34px;
    cursor: pointer;
  }
}

.personal-message {
  margin-bottom: 15%;
  margin-top: 24px;

  textarea {
    padding-top: 27px;
    // padding-bottom: 65px;
    border: 1px solid #caced2;
    max-height: 190px;

    &:hover,
    &:focus {
      border: 1px solid #caced2;
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 10px;
      background-color: $white-color;
      border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb {
      height: 6px;
      background-clip: padding-box;
      border-radius: 10px;
      background-color: $white-color;
    }

    &::-webkit-scrollbar-thumb {
      height: 6px;
      background-clip: padding-box;
      border-radius: 10px;
      background-color: #f0ebeb;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  .regards-message,
  .personal-message-label {
    position: absolute;
    left: 1px;
    top: 1px;
    padding: 3px 13px;
    background-color: $white-color;
    width: 95%;
    border-radius: 0.3rem;
    color: rgba(0, 0, 0, 0.5);
  }

  .regards-message {
    bottom: 1px;
    top: unset;
  }

  .character-limit-indicator {
    font-size: 12px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.65);
  }
}

.terms-info p {
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
}

.modal-otp-verify {
  text-align: center;
  padding: 20px 5px;
  height: 335px;

  .mobile {
    color: $primary-color;
  }

  h5 {
    color: $primary-color;
    font-weight: $font-bold;
  }

  p {
    font-size: 16px;
  }

  .otp-input {
    margin: 10px;

    input {
      width: 40px !important;
      height: 45px;
      border: 1px solid #cecece;

      &:focus {
        outline-color: $primary-color !important;
      }
    }
  }

  .otp-error {
    color: $primary-color;
    font-size: 13px;
    margin-top: 10px;
  }

  .primary-btn {
    width: 60%;
    border-radius: 24px;
    height: 47px;

    &:hover {
      background-color: $hover-red !important;
    }
  }

  .skip-link {
    color: $secondary-color;
    text-decoration: underline;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
  }
}
