.custom-checkbox {
  padding-left: 35px;

  .custom-control-label {

    &::before,
    &::after {
      border-radius: 8px !important;
    }
  }
}

.custom-radio {
  padding-left: 35px;

  input {
    display: none !important;
  }

  .custom-control-label {
    &::after {
      background-image: none;
      background-color: $white-color;
      left: -27px;
      top: 5px;
      height: 14px;
      width: 14px;
      border-radius: 50%;
    }


    // &:hover {
    //   &::before {
    //     background-color: $primary-color;
    //     border-color: $primary-color;
    //   }
    // }
  }
}

.custom-control-label {
  cursor: pointer;

  // &:hover {
  //   &::before {
  //     background-color: $primary-color;
  //     border-color: $primary-color;
  //   }

  //   &::after {
  //     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  //   }
  // }

  &::before {
    left: -34px;
    top: -2px;
    height: 28px;
    width: 28px;
    border: 1px solid #979797;
    box-shadow: none !important;
  }

  &::after {
    left: -34px;
    top: -2px;
    height: 28px;
    width: 28px;
  }
}

.custom-control-input {
  &:focus {
    outline: none;
  }
}

.custom-control-input:checked~.custom-control-label {
  &::before {
    border-color: #bbbbbb;
  }
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(147, 40, 73, 0.8);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #bbbbbb;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  background-color: $primary-color;
  border-color: $primary-color;
}

.custom-control-input:checked~.custom-control-label {
  &::before {
    background-color: $primary-color;
    // border: none;
    border-color: $primary-color;
  }
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: none;
}